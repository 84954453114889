import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ScheduleDetails {
  worker_name: string;
  scheduled_datetime: string;
  scheduled_timezone: string;
  recurrence: {
    frequency: string;
    interval: number;
    days_of_week?: number[];  // Optional for daily recurrence
    days_of_month?: number[];  // Optional for monthly recurrence
  };
}

interface FlowSubmitScreenProps {
  showModal: boolean;
  handleClose: () => void;
  handleSubmit: (scheduleDetails: ScheduleDetails) => void;
}

const FlowSubmitScreen: React.FC<FlowSubmitScreenProps> = ({ showModal, handleClose, handleSubmit }) => {
  const { t } = useTranslation();
  const [scheduleDetails, setScheduleDetails] = useState<ScheduleDetails>({
    worker_name: '',
    scheduled_datetime: '',
    scheduled_timezone: '',
    recurrence: { frequency: 'none', interval: 1, days_of_week: [], days_of_month: [] }, // Ensure both arrays are initialized
  });

  // Handle toggling days of the week
  const handleToggleWeekDay = (day: number) => {
    const { recurrence } = scheduleDetails;
    const days_of_week = recurrence.days_of_week || [];
    const updatedWeekDays = days_of_week.includes(day)
      ? days_of_week.filter(d => d !== day)
      : [...days_of_week, day];
    setScheduleDetails({ ...scheduleDetails, recurrence: { ...recurrence, days_of_week: updatedWeekDays } });
  };

  // Handle toggling days of the month
  const handleToggleDayOfMonth = (day: number) => {
    const { recurrence } = scheduleDetails;
    const days_of_month = recurrence.days_of_month || [];
    const updatedDaysOfMonth = days_of_month.includes(day)
      ? days_of_month.filter(d => d !== day)
      : [...days_of_month, day];
    setScheduleDetails({ ...scheduleDetails, recurrence: { ...recurrence, days_of_month: updatedDaysOfMonth } });
  };

  const onSubmit = () => {
    handleSubmit(scheduleDetails);
    handleClose(); // Close the modal after submission
  };

  const [timeWindows] = useState([
    "Weekday Business Hours (08:00 AM - 06:00 PM, Mon-Fri)",
    "Nightly Batch Processing (12:00 AM - 04:00 AM)",
    "End-of-Day Reports (06:00 PM - 07:00 PM)",
    "Weekend Maintenance (Saturday 12:00 AM - Sunday 06:00 AM)",
    "Month-End Processing (Last Day of Month 08:00 PM - 12:00 AM)",
    "Quarterly Financial Runs (1st of Quarter 02:00 AM - 05:00 AM)",
    "Weekly Backup Window (Sunday 02:00 AM - 04:00 AM)",
    "Daily Data Sync (03:00 AM - 05:00 AM)",
    "Morning Data Loads (06:00 AM - 08:00 AM)",
    "Nightly System Updates (11:00 PM - 01:00 AM)",
    "Weekly Reconciliation (Friday 07:00 PM - 11:00 PM)",
    "Ad-hoc Operations (24/7 Availability)",
    "Midweek Optimization (Wednesday 01:00 AM - 04:00 AM)",
    "Off-Hours Testing (Monday - Thursday 10:00 PM - 01:00 AM)",
    "Holiday Maintenance (Public Holidays 02:00 AM - 06:00 AM)",
    "Fiscal Year-End Processing (March 31st 08:00 PM - 12:00 AM)",
    "Security Patching Window (2nd Saturday 01:00 AM - 04:00 AM)",
    "Early Morning Operations (04:00 AM - 06:00 AM)",
    "Nightly File Transfers (12:00 AM - 02:00 AM)",
    "Critical Overnight Jobs (11:00 PM - 03:00 AM)",
    "Evening Batch Processing (07:00 PM - 11:00 PM)",
    "Weekend Overnight (Saturday 10:00 PM - Sunday 06:00 AM)",
    "Monthly Financial Reporting (Last Day of Month 10:00 PM - 02:00 AM)",
    "System Maintenance (Thursday 12:00 AM - 02:00 AM)",
    "Infrastructure Update Window (Every Sunday 03:00 AM - 05:00 AM)",
    "Daily Customer Data Sync (12:00 AM - 02:00 AM)",
    "Weekly Data Cleanup (Friday 10:00 PM - 01:00 AM)",
    "Backup & Recovery Window (Sunday 02:00 AM - 06:00 AM)",
    "Monthly Audit Processing (2nd Monday 02:00 AM - 04:00 AM)",
    "Year-End Close (December 31st 06:00 PM - 11:59 PM)",
    "Regular Business Hours (08:00 AM - 06:00 PM, Mon-Fri)",
    "Continuous Job Execution (24/7 Availability)",
    "Database Maintenance (1st Saturday 01:00 AM - 03:00 AM)",
    "Daily Application Monitoring (12:00 AM - 12:30 AM)",
    "Peak Usage Hours (09:00 AM - 05:00 PM, Mon-Fri)",
    "Off-Peak Batch Jobs (10:00 PM - 04:00 AM)",
    "High Priority Job Window (Monday - Friday 08:00 PM - 11:00 PM)",
    "Business Continuity Testing (3rd Friday 06:00 PM - 11:00 PM)",
    "End-of-Week Reconciliation (Friday 11:00 PM - Saturday 03:00 AM)",
    "Server Maintenance (Every 3rd Thursday 02:00 AM - 06:00 AM)",
    "Daily Processing Window (12:00 AM - 03:00 AM)",
    "Evening Operations (05:00 PM - 08:00 PM)",
    "IT Support Window (06:00 PM - 12:00 AM)",
    "Weekly Overnight Run (Thursday 01:00 AM - 04:00 AM)",
    "Nightly Cleanup (01:00 AM - 03:00 AM)",
    "Daily Archiving (11:00 PM - 12:00 AM)",
    "Monthly Billing Processing (Last Day of Month 08:00 PM - 12:00 AM)",
    "Application Update Window (Every Tuesday 02:00 AM - 05:00 AM)",
    "Holiday Overnight Jobs (12:00 AM - 04:00 AM, Holidays Only)",
  ]);

  const [selectedTimeWindow, setSelectedTimeWindow] = useState('');

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Schedule Flow</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="worker_name">
            <Form.Label>Worker</Form.Label>
            <Form.Control
              type="text"
              value={scheduleDetails.worker_name}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, worker_name: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="scheduled_datetime">
            <Form.Label>{t('scheduled-datetime')}</Form.Label>
            <Form.Control
              type="datetime-local"
              value={scheduleDetails.scheduled_datetime}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, scheduled_datetime: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="scheduled_timezone">
            <Form.Label>{t('timezone')}</Form.Label>
            <Form.Control
              type="text"
              value={scheduleDetails.scheduled_timezone}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, scheduled_timezone: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="recurrence">
            <Form.Label>{t('recurrence')}</Form.Label>
            <Form.Control
              as="select"
              value={scheduleDetails.recurrence.frequency}
              onChange={(e) =>
                setScheduleDetails({
                  ...scheduleDetails,
                  recurrence: { ...scheduleDetails.recurrence, frequency: e.target.value }
                })
              }
            >
              <option value="none">{t('none')}</option>
              <option value="day">{t('daily')}</option>
              <option value="week">{t('weekly')}</option>
              <option value="month">{t('monthly')}</option>
              <option value="year">{t('yearly')}</option>
            </Form.Control>
          </Form.Group>

          {/* Conditional rendering based on the recurrence type */}
          {scheduleDetails.recurrence.frequency === 'day' && (
            <Form.Group controlId="days_of_week">
              <Form.Label>{t('select-days-of-the-week')}</Form.Label>
              <Row>
                {[t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')].map(
                  (dayName, index) => (
                    <Col key={index} xs={6}>
                      <Form.Check
                        type="checkbox"
                        label={dayName}
                        checked={!!scheduleDetails.recurrence.days_of_week?.includes(index + 1)} // Convert undefined to false
                        onChange={() => handleToggleWeekDay(index + 1)} // Days of week are 1-based
                      />
                    </Col>
                  )
                )}
              </Row>
            </Form.Group>
          )}

          {scheduleDetails.recurrence.frequency === 'month' && (
            <Form.Group controlId="days_of_month">
              <Form.Label>{t('select-days-of-the-month')}</Form.Label>
              <Row>
                {Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
                  <Col key={day} xs={2}>
                    <Form.Check
                      type="checkbox"
                      label={String(day)}
                      checked={!!scheduleDetails.recurrence.days_of_month?.includes(day)} // Convert undefined to false
                      onChange={() => handleToggleDayOfMonth(day)}
                    />
                  </Col>
                ))}
              </Row>
            </Form.Group>
          )}

          <Form.Group controlId="time_window">
            <Form.Label>{t('select-window')}</Form.Label>
            <Form.Control
              as="select"
              value={selectedTimeWindow}
              onChange={(e) => setSelectedTimeWindow(e.target.value)}
            >
              <option value="">{t('select-time-window')}</option> {/* Default empty option */}
              {timeWindows.map((window, index) => (
                <option key={index} value={window}>
                  {window}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {t('schedule')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FlowSubmitScreen;
