import React, { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { fetchTables, deleteTable } from '../../../../redux/slices/tablesSlice'; // Import deleteTable action
import SearchBox from 'components/common/SearchBox';
import EditTableItem from './editTableItem';
import EditTableRows from './editTableRows';

const ListTables = () => {
    const dispatch = useDispatch();
    const tables = useSelector((state: any) => state.tables.items);

    const [showTableModal, setShowTableModal] = useState(false);
    const [showRowsModal, setShowRowsModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [isNew, setIsNew] = useState(false);
    const [filteredTables, setFilteredTables] = useState(tables);
    const [searchTerm, setSearchTerm] = useState<string>('');

    // Fetch all tables on component mount
    useEffect(() => {
        dispatch(fetchTables());
    }, [dispatch]);

    // Update filteredTables when tables are fetched
    useEffect(() => {
        setFilteredTables(tables);
    }, [tables]);

    // Handle search input
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.length > 0) {
            const filtered = tables.filter((table: any) =>
                table.table_name.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredTables(filtered);
        } else {
            setFilteredTables(tables);
        }
    };

    // Handle creating a new table
    const handleNew = () => {
        setCurrentIndex(null);
        setIsNew(true);
        setShowTableModal(true);
    };

    // Handle editing a table
    const handleEditTable = (index: number) => {
        setCurrentIndex(index);
        setIsNew(false);
        setShowTableModal(true);
    };

    // Handle editing rows in a table
    const handleEditRows = (index: number) => {
        setCurrentIndex(index);
        setShowRowsModal(true);
    };

    // Handle deleting a table
    const handleDeleteTable = (table_id: number) => {
        if (window.confirm('Are you sure you want to delete this table?')) {
            dispatch(deleteTable(table_id));
        }
    };

    return (
        <Container fluid className="p-0">
            <Row className="m-0 mb-4">
                <Col className="p-0 d-flex justify-content-between align-items-center">
                    <SearchBox
                        placeholder="Search..."
                        size="sm"
                        onChange={handleSearch}
                    />
                    <Button variant="outline-primary" size="sm" onClick={handleNew}>
                        New Table
                    </Button>
                </Col>
            </Row>

            <Row className="m-0">
                <Col className="p-0">
                    <table className="table table-hover table-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Table Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTables.map((table: any, i: number) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{table.table_name}</td>
                                    <td>
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleEditTable(i)}
                                        >
                                            Edit Table
                                        </Button>{' '}
                                        <Button
                                            variant="outline-secondary"
                                            className="ml-2"
                                            onClick={() => handleEditRows(i)}
                                        >
                                            Edit Rows
                                        </Button>{' '}
                                        <Button
                                            variant="outline-danger"
                                            className="ml-2"
                                            onClick={() => handleDeleteTable(table.table_id)}
                                        >
                                            Delete Table
                                        </Button>{' '}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {showTableModal && (
                        <EditTableItem
                            tableIndex={currentIndex}
                            isNew={isNew}
                            handleClose={() => setShowTableModal(false)}
                        />
                    )}

                    {showRowsModal && (
                        <EditTableRows
                            tableIndex={currentIndex}
                            handleClose={() => setShowRowsModal(false)}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default ListTables;
