import React from 'react';
import { Form } from 'react-bootstrap';

interface ConnectionSapProps {
    connectionData: any;
    handleParameterChange: (key: string, value: any) => void;
}

const ConnectionSap: React.FC<ConnectionSapProps> = ({ connectionData, handleParameterChange }) => {
    return (
        <>
            <Form.Group controlId="host">
                <Form.Label>Host</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.host || ''}
                    onChange={(e) => handleParameterChange('host', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="sapSystemId">
                <Form.Label>SAP System ID</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.sap_system_id || ''}
                    onChange={(e) => handleParameterChange('sap_system_id', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="client">
                <Form.Label>Client</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.client || ''}
                    onChange={(e) => handleParameterChange('client', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="language">
                <Form.Label>Language</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.language || ''}
                    onChange={(e) => handleParameterChange('language', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.username || ''}
                    onChange={(e) => handleParameterChange('username', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    value={connectionData.connection_parameters.password || ''}
                    onChange={(e) => handleParameterChange('password', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="maximumnumberofrfcconnections">
                <Form.Label>Max nr. of RFC Connections</Form.Label>
                <Form.Control
                    type="number"
                    value={connectionData.connection_parameters.maximumnumberofrfcconnections || ''}
                    onChange={(e) => handleParameterChange('maximumnumberofrfcconnections', parseInt(e.target.value, 10) || 0)} // Parse as integer
                />
            </Form.Group>
        </>
    );
};

export default ConnectionSap;
