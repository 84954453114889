import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Image } from 'react-bootstrap';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  // Fetch language from Redux state
  const userLanguage = useSelector((state: RootStateOrAny) => state.auth.userData?.language || 'en'); // Default to 'nl' if no language is set

  const languages = [
    { code: 'en', name: 'English', flag: 'https://flagcdn.com/w20/gb.png' }, // UK Flag
    { code: 'nl', name: 'Nederlands', flag: 'https://flagcdn.com/w20/nl.png' }, // Netherlands Flag
  ];

  // Change language manually through dropdown
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  // Set language automatically based on user data


  // Get the current language from i18n
  const currentLanguage = languages.find(lng => lng.code === i18n.language) || languages[0];

  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="dropdown-basic">
        {/* Automatically show the correct flag based on currentLanguage */}
        <Image src={currentLanguage.flag} alt={currentLanguage.name} style={{ width: '20px', marginRight: '5px' }} />
        {currentLanguage.name} {/* Optionally show the name of the language */}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {languages.map((language) => (
          <Dropdown.Item key={language.code} onClick={() => changeLanguage(language.code)}>
            <Image src={language.flag} alt={language.name} style={{ width: '20px', marginRight: '5px' }} />
            {language.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitcher;
