import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import Spreadsheet from 'react-spreadsheet';
import {
    fetchTableRows,
    createTableRow,
    updateTableRow,
    deleteTableRows,
} from '../../../../redux/slices/tableRowSlice';

interface EditTableRowsProps {
    tableIndex: number | null;
    handleClose: () => void;
}

const EditTableRows: React.FC<EditTableRowsProps> = ({ tableIndex, handleClose }) => {
    const dispatch = useDispatch();
    const tables = useSelector((state: any) => state.tables.items);
    const table = tables[tableIndex!];

    const rows = useSelector((state: any) => state.tableRows.items);
    const errorMessage = useSelector((state: any) => state.tableRows.errorMessage);
    const [spreadsheetData, setSpreadsheetData] = useState<any[][]>([]);
    const [columns, setColumns] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [showError, setShowError] = useState(false);

    // Fetch table rows
    useEffect(() => {
        if (table) {
            dispatch(fetchTableRows(table.table_id));
        }
    }, [table, dispatch]);

    // Process rows into spreadsheet data
    useEffect(() => {
        if (table && table.table_columns) {
            const initialColumns = table.table_columns.map((col: any) => col.name);
            setColumns(initialColumns);

            if (rows.length > 0) {
                // Ensure that all rows include values for all columns in table_columns
                const initialData = rows.map((row: any) =>
                    initialColumns.map((colName) => ({
                        value: row.values[colName] !== undefined ? row.values[colName] : null, // Set null if column value is missing
                    }))
                );
                setSpreadsheetData(initialData);
            } else {
                // Initialize spreadsheet with empty rows if no rows exist
                setSpreadsheetData([]);
            }
        }
    }, [rows, table]);

    // Handle row updates
    const handleRowChange = useCallback((rowIndex: number, colIndex: number, value: any) => {
        setSpreadsheetData((prevData) => {
            const updatedData = [...prevData];
            updatedData[rowIndex] = [...updatedData[rowIndex]];  // Copy the row

            // Check if the cell is emptied
            if (value === '') {
                // Set the value to null if the cell is emptied
                updatedData[rowIndex][colIndex] = { value: null };

                // Optionally update the row values in Redux to include null
                const updatedRowValues = { ...rows[rowIndex].values, [columns[colIndex]]: null };

                // Dispatch the update to the Redux store or backend
                dispatch(updateTableRow({
                    ...rows[rowIndex],
                    values: updatedRowValues,
                }));
            } else {
                updatedData[rowIndex][colIndex] = { value };
            }

            return updatedData;
        });
    }, [columns, rows, dispatch]);

    // Add new row with default empty values for all columns in table_columns
    const handleAddRow = () => {
        const newRow = columns.map(() => ({ value: null }));  // Initialize with null values
        setSpreadsheetData((prevData) => [...prevData, newRow]);
    };

    // Save all rows
    const handleSaveRows = () => {
        spreadsheetData.forEach((rowData, rowIndex) => {
            const rowValues: any = {};
            columns.forEach((colName, colIndex) => {
                rowValues[colName] = rowData[colIndex]?.value || '';
            });

            if (rows[rowIndex]) {
                const updatedRow = {
                    table_id: table.table_id,
                    table_row_id: rows[rowIndex].table_row_id,
                    values: rowValues,
                };
                dispatch(updateTableRow(updatedRow));
            } else {
                dispatch(createTableRow({ table_id: table.table_id, values: rowValues }));
            }
        });
    };

    // Handle row selection using range (start and end)
    const handleSelect = (selection: any) => {
        if (selection.constructor.name === "EntireRowsSelection") {
            const selectedRowIndexes = [];
            for (let i = selection.start; i <= selection.end; i++) {
                selectedRowIndexes.push(i);
            }
            setSelectedRows(selectedRowIndexes);
        }
    };

    // Function to delete selected rows
    const handleDeleteSelected = async () => {
        const rowsToDelete = selectedRows.map((rowIndex) => rows[rowIndex].table_row_id);
        try {
            await dispatch(deleteTableRows({ table_id: table.table_id, table_row_ids: rowsToDelete }));
            setSelectedRows([]);
        } catch (error) {
            setShowError(true);
        }
    };

    return (
        <Modal show={true} onHide={handleClose} fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Rows for Table: {table?.table_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="spreadsheet-container" style={{ height: 'calc(100vh - 218px)', overflow: 'auto' }}>
                    <Form>
                        <Spreadsheet
                            data={spreadsheetData}
                            onChange={(updatedData: any[][]) => setSpreadsheetData(updatedData)}
                            columnLabels={columns}
                            onSelect={handleSelect}
                        />
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%', marginBottom: '10px', textAlign: 'center' }}>
                    <Button variant="primary" onClick={handleAddRow} className="mr-2">
                        Add Row
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDeleteSelected}
                        className="mr-2"
                        disabled={selectedRows.length === 0}
                    >
                        Delete Selected
                    </Button>
                </div>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="success" onClick={handleSaveRows}>
                    Save All
                </Button>
            </Modal.Footer>
            <Alert variant="danger" show={showError} onClose={() => setShowError(false)} dismissible>
                <Alert.Heading>Failed to delete rows</Alert.Heading>
                <p>{errorMessage}</p>
            </Alert>
        </Modal>
    );
};

export default EditTableRows;
