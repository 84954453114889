import React, { useRef, useEffect } from 'react';

const FlowInstanceNodeContextMenu = ({ menuPosition, selectedNode, onOpenDetails, onDeleteNode, onClose }) => {
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={menuRef}
      style={{
        position: 'absolute',
        top: `${menuPosition.y}px`,
        left: `${menuPosition.x}px`,
        zIndex: 1000,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        padding: '0px',
        width: '200px',
      }}
    >
      <ul
        style={{
          listStyleType: 'none',
          margin: '0',
          padding: '0',
        }}
      >
        <li
          style={{
            padding: '10px',
            cursor: 'pointer',
            borderBottom: '1px solid #eaeaea',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5f5f5')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
          onClick={() => onOpenDetails(selectedNode)}
        >
          Open Details
        </li>
        <li
          style={{
            padding: '10px',
            cursor: 'pointer',
            borderBottom: '1px solid #eaeaea',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5f5f5')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
          onClick={() => onOpenDetails(selectedNode)}
        >
          Rerun Node
        </li>
        <li
          style={{
            padding: '10px',
            cursor: 'pointer',
            borderBottom: '1px solid #eaeaea',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5f5f5')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
          onClick={() => onOpenDetails(selectedNode)}
        >
          Rerun Flow from here
        </li>
        <li
          style={{
            padding: '10px',
            cursor: 'pointer',
            borderBottom: '1px solid #eaeaea',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5f5f5')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
          onClick={() => onDeleteNode(selectedNode)}
        >
          Delete Node
        </li>
      </ul>
    </div>
  );
};

export default FlowInstanceNodeContextMenu;
