import React from 'react';
import { Handle, Position } from 'reactflow';

const DecisionNode = ({ id, data }) => {
  // Base height is the initial height of the node, without conditions
  const baseHeight = 28;

  // Calculate total height dynamically based on the number of conditions
  const totalHeight = baseHeight + (data.conditions?.length || 1) * 27;

  return (
    <div className="dndnode decision" style={{ height: `${totalHeight}px` }}>
      <div>{data.label}</div>
      <div style={{ fontSize: '0.75em', color: '#555' }}>ID: {id}</div>
      <Handle type="target" position={Position.Left} />
      {data.conditions?.map((condition, index) => (
        <div
          key={`${id}-condition-${index}`}
          style={{ position: 'absolute', top: `${(index + 1) * 27}px`, right: '0px', display: 'flex' }}
        >
          <span style={{ fontSize: '0.75em', marginRight: '5px' }}>
            {condition.key}
          </span>
          <Handle
            id={`${id}-handle-${index}`}
            type="source"
            position={Position.Right}
          />
        </div>
      ))}
    </div>
  );
};

export default DecisionNode;
