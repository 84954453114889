import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Row, Col, Table, Pagination, Modal, Form } from 'react-bootstrap';

import api from '../../../../services/api';

const ListAuditTrail = () => {
    const { t } = useTranslation();
    const [auditTrail, setAuditTrail] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);  // Number of items per page
    const [selectedEntry, setSelectedEntry] = useState(null);  // For storing the selected entry for the modal
    const [showModal, setShowModal] = useState(false);  // Controls modal visibility

    // Fetch audit trail data with pagination
    const fetchAuditTrail = async (pageNumber, itemsPerPage) => {
        setLoading(true);
        try {
            const response = await api.get(`/audit_trail?page=${pageNumber}&limit=${itemsPerPage}`);
            const { data, totalPages } = response.data;

            setAuditTrail(data);
            setTotalPages(totalPages);
            setPage(pageNumber);
        } catch (error) {
            console.error('Error fetching audit trail:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch data on component mount and when page or limit changes
    useEffect(() => {
        fetchAuditTrail(page, limit);
    }, [page, limit]);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setPage(pageNumber);
        }
    };

    // Handle items per page change
    const handleLimitChange = (event) => {
        const newLimit = parseInt(event.target.value);
        setLimit(newLimit);
        setPage(1);  // Reset to the first page whenever limit changes
    };

    // Handle row click to show modal with full audit details
    const handleRowClick = (entry) => {
        setSelectedEntry(entry);  // Set the clicked entry as the selected one
        setShowModal(true);  // Show the modal
    };

    // Handle modal close
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedEntry(null);
    };

    return (
        <Container fluid className="p-0">
            <Row className="m-0 mb-4">
                <Col className="p-0 d-flex justify-content-between align-items-center">
                    <h1>{t('audit_trail.title')}</h1>
                    <Form.Group controlId="itemsPerPageSelect">
                        <Form.Label>{t('items_per_page')}</Form.Label>
                        <Form.Control as="select" value={limit} onChange={handleLimitChange} size="sm">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="m-0">
                <Col className="p-0">
                    {loading ? (
                        <p>{t('loading')}...</p>
                    ) : (
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('audit_trail.created_at').toUpperCase()}</th>
                                    <th>{t('user').toUpperCase()}</th>
                                    <th>{t('audit_trail.audit_type').toUpperCase()}</th>
                                    <th>{t('audit_trail.details').toUpperCase()}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {auditTrail.map((entry, index) => (
                                    <tr key={entry.audit_trail_id} onClick={() => handleRowClick(entry)} style={{ cursor: 'pointer' }}>
                                        <td>{(page - 1) * limit + index + 1}</td>
                                        <td>{new Date(entry.created_at).toLocaleString()}</td>
                                        <td>{entry.username}</td>
                                        <td>{entry.audit_type}</td>
                                        <td>
                                            {entry.audit_type === "API_CALL"
                                                ? entry.audit_details.endpoint
                                                : JSON.stringify(entry.audit_details)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    <Pagination className="justify-content-center">
                        <Pagination.First onClick={() => handlePageChange(1)} disabled={page === 1} />
                        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
                        {[...Array(totalPages).keys()].map((i) => (
                            <Pagination.Item
                                key={i + 1}
                                active={i + 1 === page}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} />
                        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={page === totalPages} />
                    </Pagination>
                </Col>
            </Row>

            {/* Modal to show full audit details */}
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t('audit_trail.details')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedEntry && (
                        <pre>{JSON.stringify(selectedEntry.audit_details, null, 2)}</pre>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ListAuditTrail;
