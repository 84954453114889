import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece
} from '@iconscout/react-unicons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

// Define the structure of a route item
interface RouteItem {
  name: string;
  path: string;
  pathName: string;
  active: boolean;
  icon?: string;
  dropdownInside?: boolean;
  pages?: RouteItem[];
}

export const getRoutes = (t: any): RouteItems[] => {
  const CusRouteItems: RouteItems[] = [
    {
      label: 'dashboard',
      horizontalNavLabel: 'home',
      active: true,
      icon: UilChartPie,
      labelDisabled: true,
      pages: [
        {
          name: 'Home',
          icon: 'home',
          active: true,
          flat: true,
          path: '/',
          pathName: 'default-dashboard',
        },
      ]
    },
    {
      label: 'Process Automation',
      icon: UilCube,
      pages: [
        {
          name: t('menu.flowDefinitions'),
          active: true,
          icon: 'home',
          path: '/apps/scheduler/flows',
        },
        {
          name: t('menu.flowInstances'),
          active: true,
          icon: 'home',
          path: '/apps/scheduler/flowsinstances',
        },
      ]
    },
    {
      label: t('menu.configuration'),
      icon: UilCube,
      pages: [
        {
          name: t('menu.generalConfig'),
          active: true,
          icon: 'home',
          path: '/configuration/configitems',
        },
        {
          name: t('menu.connections'),
          active: true,
          icon: 'home',
          path: '/apps/scheduler/databaseconnections',
        },
        {
          name: t('menu.tables'),
          active: true,
          icon: 'home',
          path: '/configuration/tables',
        },
      ]
    },
    {
      label: t('security'),
      icon: UilFilesLandscapesAlt,
      pages: [
        {
          name: t('menu.users'),
          icon: 'users',
          path: 'configuration/users',
          active: true
        },
        {
          name: t('menu.roles'),
          icon: 'users',
          path: 'configuration/roles',
          active: true
        },
      ]
    },
  ];

  return CusRouteItems;
};