import { useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateTable, createTable } from '../../../../redux/slices/tablesSlice';

interface EditTableItemProps {
    tableIndex: number | null;
    isNew?: boolean;
    handleClose: () => void;
}

interface Column {
    name: string;
    type: string;
}

const EditTableItem: React.FC<EditTableItemProps> = ({ tableIndex, isNew = false, handleClose }) => {
    const dispatch = useDispatch();
    const currentTable = useSelector((state: any) => tableIndex !== null ? state.tables.items[tableIndex] : null);

    const [tableName, setTableName] = useState<string>(currentTable?.table_name || '');
    const [columns, setColumns] = useState<Column[]>(currentTable?.table_columns || [{ name: '', type: 'text' }]);

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        const updatedTable = {
            table_name: tableName,
            table_columns: columns,
            table_privileges: {}  // Empty for now, you can implement this part later
        };
        if (isNew) {
            dispatch(createTable(updatedTable));
        } else {
            dispatch(updateTable({ table_id: currentTable.table_id, ...updatedTable }));
        }
        handleClose();
    };

    const handleColumnChange = (index: number, field: string, value: string) => {
        const updatedColumns = columns.map((col, i) =>
            i === index ? { ...col, [field]: value } : col
        );
        setColumns(updatedColumns);
    };

    const handleAddColumn = () => {
        setColumns([...columns, { name: '', type: 'text' }]);
    };

    const handleRemoveColumn = (index: number) => {
        const updatedColumns = columns.filter((_, i) => i !== index);
        setColumns(updatedColumns);
    };

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{isNew ? 'New Table' : 'Edit Table'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSave}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">Table Name</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                value={tableName}
                                onChange={(e) => setTableName(e.target.value)}
                                required
                            />
                        </Col>
                    </Form.Group>

                    <hr />

                    <h5>Table Columns</h5>
                    {columns.map((col, index) => (
                        <Row key={index} className="align-items-center mb-2">
                            <Col sm="5">
                                <Form.Control
                                    type="text"
                                    placeholder="Column Name"
                                    value={col.name}
                                    onChange={(e) => handleColumnChange(index, 'name', e.target.value)}
                                    required
                                />
                            </Col>
                            <Col sm="4">
                                <Form.Select
                                    value={col.type}
                                    onChange={(e) => handleColumnChange(index, 'type', e.target.value)}
                                >
                                    <option value="text">Text</option>
                                    <option value="int">Integer</option>
                                </Form.Select>
                            </Col>
                            <Col sm="3">
                                <Button
                                    variant="danger"
                                    onClick={() => handleRemoveColumn(index)}
                                    disabled={columns.length === 1} // Disable remove if only one column
                                >
                                    Remove
                                </Button>
                            </Col>
                        </Row>
                    ))}
                    <Button variant="primary" onClick={handleAddColumn}>
                        Add Column
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditTableItem;
