import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

// Fetch all roles
export const fetchRoles = createAsyncThunk('roles/fetchRoles', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get('/roles');
        return response.data; // response includes ssoroleid and privileges
    } catch (e) {
        return rejectWithValue('');
    }
});

// Fetch a single role by ID with its privileges and ssoroleid
export const fetchRole = createAsyncThunk('roles/fetchRole', async (role_id: string, { rejectWithValue }) => {
    try {
        const response = await api.get(`/roles/${role_id}`);
        return response.data; // The response contains role data with ssoroleid and privileges
    } catch (e) {
        return rejectWithValue('');
    }
});

// Create a new role with optional ssoroleid and privileges
export const createRole = createAsyncThunk('roles/createRole', async (payload: { name: string; ssoroleid?: string; privileges?: any[] }, { rejectWithValue }) => {
    try {
        const response = await api.post('/roles', payload); // Send ssoroleid if provided
        return response.data; // Response includes the created role with ssoroleid
    } catch (e) {
        return rejectWithValue('');
    }
});

// Update a role by ID with optional ssoroleid and privileges
export const updateRole = createAsyncThunk('roles/updateRole', async (payload: { role_id: string; name: string; ssoroleid?: string; privileges?: any[] }, { rejectWithValue }) => {
    try {
        const response = await api.put(`/roles/${payload.role_id}`, payload); // Send ssoroleid if provided
        return response.data; // Response includes the updated role with ssoroleid
    } catch (e) {
        return rejectWithValue('');
    }
});

// Delete a role by ID
export const deleteRole = createAsyncThunk('roles/deleteRole', async (role_id: string, { rejectWithValue }) => {
    try {
        await api.delete(`/roles/${role_id}`);
        return { role_id };
    } catch (e) {
        return rejectWithValue('');
    }
});