import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';

// import { ChangeEvent } from 'react';
// import SearchBox from 'components/common/SearchBox';

import { ButtonGroup } from "react-bootstrap";
import { Button, Table, Container, Row, Col } from 'react-bootstrap';

// import EditListener from "./editListener";
// import CreateListener from "./createListener";

import { fetchRoles, deleteRole } from "../../../../redux/slices/rolesThunk";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

import EditRole from "./editRole";
import CreateRole from "./createRole";

const ListRoles = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const roles = useSelector((state: RootStateOrAny) => state.roles.roles)
    const rolesLoading = useSelector((state: RootStateOrAny) => state.roles.loading)

    useEffect(() => {
        // getFiles();
        dispatch(fetchRoles());
    }, []);

    const handleDelete = async (role_id: string) => {
        try {
            console.log("handleDelete: " + role_id);
            dispatch(deleteRole(role_id));  // Pass role_id directly as a string
        } catch (error) {
            console.error(error.message);
        }
    }
    






    return (
        <div>
            <Row className="m-0 mb-4">
                <Col className="p-0 d-flex justify-content-between align-items-center">
                <h1>{t('menu.roles')}</h1>
                <EditRole newRole />
                </Col>
            </Row>
            <Row className="m-0">
                <Col className="p-0">
                
               
            <Table hover size='sm' >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t('name')}</th>
                        <th>{t('action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(roles).map(([key, role], i) => (
                        <tr key={i}>
                            <td>{roles[i].role_id}</td>
                            <td>{roles[i].name}</td>
                            <td>
                                <ButtonGroup aria-label="Basic example">
                                    <EditRole stateId={i} />
                                    <Button variant="phoenix-secondary" onClick={() => handleDelete(roles[i].role_id)}>{t('button.delete')}</Button>
                                </ButtonGroup>
                                </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            
            </Col>
            </Row>

        </div>

    )
};





export default ListRoles;