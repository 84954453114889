import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

// Define the shape of a User
interface User {
    user_id: number;
    username: string;
    password?: string; // Password is optional when fetching users
    home_folder?: string;
    public_key?: string; // Add public_key here as optional
    mail?: string;
    user_config?: Record<string, any>;
  }
  
  // Define the state type
  interface UserState {
    users: User[];
    loading: boolean;
    createLoading: boolean;
    updateLoading: boolean;
    deleteLoading: boolean;
    error: string | null;
  }

// Thunks for asynchronous operations
export const fetchUsers = createAsyncThunk<User[]>('users/fetchUsers', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get('/users');
        return response.data as User[]; // Cast the response as an array of User objects
    } catch (error) {
        return rejectWithValue(error.response?.data || 'Error fetching users');
    }
});

export const createUser = createAsyncThunk<User, Partial<User>>('users/createUser', async (user, { rejectWithValue }) => {
    try {
        const response = await api.post('/users', user);
        return response.data as User; // Cast the response as a User object
    } catch (error) {
        return rejectWithValue(error.response?.data || 'Error creating user');
    }
});

export const updateUser = createAsyncThunk<User, User>('users/updateUser', async (user, { rejectWithValue }) => {
    try {
        const response = await api.put(`/users/${user.user_id}`, user);
        return response.data as User; // Cast the response as a User object
    } catch (error) {
        return rejectWithValue(error.response?.data || 'Error updating user');
    }
});

export const deleteUser = createAsyncThunk<number, number>('users/deleteUser', async (user_id, { rejectWithValue }) => {
    try {
        await api.delete(`/users/${user_id}`);
        return user_id; // Return the user_id after successful deletion
    } catch (error) {
        return rejectWithValue(error.response?.data || 'Error deleting user');
    }
});

// Initial state for users slice
const initialState: UserState = {
    users: [],
    loading: false,
    createLoading: false,
    updateLoading: false,
    deleteLoading: false,
    error: null,
};

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch users
            .addCase(fetchUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            // Create user
            .addCase(createUser.pending, (state) => {
                state.createLoading = true;
                state.error = null;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.createLoading = false;
                state.users.push(action.payload);
            })
            .addCase(createUser.rejected, (state, action) => {
                state.createLoading = false;
                state.error = action.payload as string;
            })

            // Update user
            .addCase(updateUser.pending, (state) => {
                state.updateLoading = true;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.updateLoading = false;
                const index = state.users.findIndex((user) => user.user_id === action.payload.user_id);
                if (index !== -1) {
                    state.users[index] = action.payload;
                }
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.updateLoading = false;
                state.error = action.payload as string;
            })

            // Delete user
            .addCase(deleteUser.pending, (state) => {
                state.deleteLoading = true;
                state.error = null;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.deleteLoading = false;
                state.users = state.users.filter((user) => user.user_id !== action.payload);
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.deleteLoading = false;
                state.error = action.payload as string;
            });
    },
});

export default userSlice.reducer;
