import React, { useEffect, useState } from 'react';
import { AuthProvider } from 'react-oauth2-code-pkce';
import { getOauthConfig } from './services/oauth';  // your config loader function

const OAuth2Provider = ({ children }) => {
  const [oauthConfig, setOauthConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const config = await getOauthConfig(); // Fetch OAuth config
        setOauthConfig(config);
        setLoading(false);  // Set loading to false when config is loaded
      } catch (error) {
        console.error("Failed to load OAuth config:", error);
        setLoading(false);  // Stop loading if there's an error
      }
    };
    loadConfig();
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <AuthProvider
      authConfig={{
        clientId: oauthConfig.clientId,
        authorizationEndpoint: oauthConfig.authorizationEndpoint,
        tokenEndpoint: oauthConfig.tokenEndpoint,
        redirectUri: oauthConfig.redirectUri,
        scope: "openid profile email User.Read",
      }}
    >
      {children}
    </AuthProvider>
  );
};

export default OAuth2Provider;
