import React from 'react';
import { Form } from 'react-bootstrap';

interface ConnectionRestProps {
    connectionData: any;
    handleParameterChange: (key: string, value: any) => void;
}

const ConnectionRest: React.FC<ConnectionRestProps> = ({ connectionData, handleParameterChange }) => {
    return (
        <>
            <Form.Group controlId="baseUrl">
                <Form.Label>Base URL</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.base_url || ''}
                    onChange={(e) => handleParameterChange('base_url', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="token">
                <Form.Label>Token</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.token || ''}
                    onChange={(e) => handleParameterChange('token', e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="timeout">
                <Form.Label>Timeout</Form.Label>
                <Form.Control
                    type="number"
                    value={connectionData.connection_parameters.timeout || ''}
                    onChange={(e) => handleParameterChange('timeout', parseInt(e.target.value, 10) || 0)} // Parse as integer
                />
            </Form.Group>
        </>
    );
};

export default ConnectionRest;
