export const getToken = () => {
    return localStorage.getItem('token');
}

export const removeToken = () => {
    localStorage.removeItem('token');
}

export const setToken = (val) => {
    localStorage.setItem('token', val);
}


export const getUserData = () => {
    return localStorage.getItem('userData');
}

export const removeUserData = () => {
    localStorage.removeItem('userData');
}

export const setUserData = (val) => {
    localStorage.setItem('userData', val);
}