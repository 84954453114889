import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../../redux/slices/auth';
import api from 'services/api';  // Your API service for backend calls

const AuthCallback = () => {
  const authContext = useContext(AuthContext);  // Access the context
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const authenticateWithBackend = async (token) => {
      try {
        // Send token to backend for validation and to fetch user data
        const response = await api.post('/auth/validate-azuread-token', { token });
        // Dispatch the loginSuccess action with backend-verified user data
        console.log('validate-token response: '+JSON.stringify(response))
        dispatch(
          loginSuccess({
            token: response.data.token,
            // userData: response.data.user,  // Include user data from the backend
          })
        );
        api.defaults.headers.common['Authorization'] = response.data.token;
        // Redirect after successful login
        navigate('/');
      } catch (error) {
        console.error('Error during backend authentication:', error);
      }
    };

    if (authContext?.idToken) {
      console.log("authContext: " + JSON.stringify(authContext));

      // Call the backend for further validation and user data retrieval
      authenticateWithBackend("Bearer " + authContext.idToken);
    } else if (authContext?.error) {
      console.error('Authentication error:', authContext.error);
    } else {
      console.log('Waiting for token...');
    }
  }, [authContext, dispatch, navigate]);

  return <div>Authenticating...</div>;
};

export default AuthCallback;
