import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

interface Config {
    config_id: number;
    key: string;
    value: object; // Use `any` here as the value can be different types
}

interface ConfigState {
    items: Config[];
    status: string | null;
    currentConfig: Config | null; // Store a single config item
    currentConfigStatus: string | null; // Status for fetching a single config
}

const initialState: ConfigState = {
    items: [],
    status: null,
    currentConfig: null, // Initial state for the single config
    currentConfigStatus: null, // Initial status for fetching single config
};

// Fetch all configurations
export const fetchConfigs = createAsyncThunk('config/fetchConfigs', async () => {
    const response = await api.get('/config');
    return response.data;
});

// Fetch details of a single configuration
export const fetchConfig = createAsyncThunk('config/fetchConfig', async (key: string) => {
    const response = await api.get(`/config/${key}`);
    return response.data;
});

// Create a new configuration
export const createConfig = createAsyncThunk('config/createConfig', async (config: Omit<Config, 'config_id'>) => {
    const response = await api.post('/config', config);
    return response.data;
});

// Update an existing configuration
export const updateConfig = createAsyncThunk('config/updateConfig', async (config: Config) => {
    const response = await api.put(`/config`, config);
    return response.data;
});

// Delete a configuration
export const deleteConfig = createAsyncThunk('config/deleteConfig', async (config_id: number) => {
    await api.delete(`/config/${config_id}`);
    return config_id;
});

// Config Slice
const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch all configs
            .addCase(fetchConfigs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchConfigs.fulfilled, (state, action) => {
                state.items = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchConfigs.rejected, (state) => {
                state.status = 'failed';
            })
            // Fetch a single config
            .addCase(fetchConfig.pending, (state) => {
                state.currentConfigStatus = 'loading';
            })
            .addCase(fetchConfig.fulfilled, (state, action) => {
                state.currentConfig = action.payload;
                state.currentConfigStatus = 'succeeded';
            })
            .addCase(fetchConfig.rejected, (state) => {
                state.currentConfigStatus = 'failed';
            })
            // Create new config
            .addCase(createConfig.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            // Update config
            .addCase(updateConfig.fulfilled, (state, action) => {
                const index = state.items.findIndex(
                    (config) => config.config_id === action.payload.config_id
                );
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            })
            // Delete config
            .addCase(deleteConfig.fulfilled, (state, action) => {
                state.items = state.items.filter((config) => config.config_id !== action.payload);
            });
    },
});

export default configSlice.reducer;
