import axios from 'axios';
import { getBackendUrl } from './backendUrl';

// Function to create the Axios instance
const createApiInstance = async () => {
  const baseURL = await getBackendUrl();

  const api = axios.create({
    baseURL,
  });

  if (localStorage.getItem('token')) {
    // const user = JSON.parse(localStorage.getItem('user'));
    // Set the Authorization header if user token is available
    api.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }
  console.log("api url: " + baseURL)
  return api;
};

// Export the Axios instance
export const api = await createApiInstance();
export default api;
