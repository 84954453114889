import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

interface Worker {
    worker_id: number;
    worker_name: string;
}

interface WorkerState {
    items: Worker[];
    status: string | null;
    error: string | null;
}

const initialState: WorkerState = {
    items: [],
    status: null,
    error: null,
};

// Thunk for fetching all workers
export const fetchWorkers = createAsyncThunk('workers/fetchWorkers', async () => {
    const response = await api.get('/workers');
    return response.data;
});

// Thunk for creating a new worker
export const createWorker = createAsyncThunk(
    'workers/createWorker',
    async ({ worker_name, worker_secret }: { worker_name: string; worker_secret: string }) => {
        const response = await api.post('/workers', { worker_name, worker_secret });
        return response.data;
    }
);

// Thunk for updating an existing worker
export const updateWorker = createAsyncThunk(
    'workers/updateWorker',
    async ({ worker_id, worker_name, worker_secret }: { worker_id: number; worker_name?: string; worker_secret?: string }) => {
        const response = await api.put(`/workers/${worker_id}`, { worker_name, worker_secret });
        return response.data;
    }
);

// Thunk for deleting a worker
export const deleteWorker = createAsyncThunk(
    'workers/deleteWorker',
    async (worker_id: number) => {
        await api.delete(`/workers/${worker_id}`);
        return worker_id;
    }
);

const workersSlice = createSlice({
    name: 'workers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle fetchWorkers
            .addCase(fetchWorkers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWorkers.fulfilled, (state, action) => {
                state.items = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchWorkers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch workers';
            })
            // Handle createWorker
            .addCase(createWorker.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            .addCase(createWorker.rejected, (state, action) => {
                state.error = action.error.message || 'Failed to create worker';
            })
            // Handle updateWorker
            .addCase(updateWorker.fulfilled, (state, action) => {
                const index = state.items.findIndex(worker => worker.worker_id === action.payload.worker_id);
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            })
            .addCase(updateWorker.rejected, (state, action) => {
                state.error = action.error.message || 'Failed to update worker';
            })
            // Handle deleteWorker
            .addCase(deleteWorker.fulfilled, (state, action) => {
                state.items = state.items.filter(worker => worker.worker_id !== action.payload);
            })
            .addCase(deleteWorker.rejected, (state, action) => {
                state.error = action.error.message || 'Failed to delete worker';
            });
    },
});

export default workersSlice.reducer;
