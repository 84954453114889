import { useTranslation } from 'react-i18next';
import { Nav, Navbar } from 'react-bootstrap';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import React, { useEffect, useState } from "react";
import {
  UilArrowFromRight,
  UilLeftArrowToLeft,
} from '@iconscout/react-unicons';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import Button from 'components/base/Button';
import NavbarTopNav from '../navbar-horizontal/NavbarTopNav';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarVerticalCollapseProvider from './NavbarVerticalCollapseProvider';
import api from 'services/api'; // Ensure this is correctly set up for your API calls
import { capitalize } from 'helpers/utils';

const NavbarVertical = (props) => {
  const { t } = useTranslation();
  const [routes, setRoutes] = useState([]); // State to store fetched routes
  const [loading, setLoading] = useState(true); // Loading state

  const loggedIn = props.loggedIn;

  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarVerticalAppearance,
      isNavbarVerticalCollapsed,
    },
    setConfig,
  } = useAppContext();

  const { breakpoints } = useBreakpoints();

  useEffect(() => {
    // Function to fetch the routes from the API
    const fetchRoutes = async () => {
      try {
        const response = await api.get('/pages'); // Call the API
        setRoutes(response.data); // Update the state with fetched routes
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Error fetching routes:', error);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchRoutes(); // Fetch the routes on component mount
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Display a loading state while data is being fetched
  }

  return (
    <NavbarVerticalCollapseProvider>
      <Navbar
        className={classNames('navbar-vertical', {
          'navbar-darker': navbarVerticalAppearance === 'darker',
        })}
        expand="lg"
        variant=""
      >
        <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
          <div className="navbar-vertical-content">
            <Nav className="flex-column" as="ul" id="navbarVerticalNav">
              {routes.map((route) => (
                <Nav.Item key={route.label}>
                  {!route.labelDisabled && (
                    <>
                      <p className="navbar-vertical-label">
                        {capitalize(route.label)}
                      </p>
                      <hr className="navbar-vertical-line" />
                    </>
                  )}
                  <NavbarVerticalMenu level={1} routes={route.pages} />
                </Nav.Item>
              ))}
            </Nav>

            {navbarPosition === 'combo' && breakpoints.down('lg') && (
              <div className="move-container">
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr" />
                </div>
                <NavbarTopNav />
              </div>
            )}
          </div>
        </Navbar.Collapse>
        <div className="navbar-vertical-footer">
          <Button
            className="navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"
            onClick={() => {
              setConfig({
                isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed,
              });
            }}
          >
            {isNavbarVerticalCollapsed ? (
              <UilArrowFromRight size={16} className="mb-1" />
            ) : (
              <>
                <UilLeftArrowToLeft size={16} className="mb-1" />
                <span className="ms-2">Collapsed View</span>
              </>
            )}
          </Button>
        </div>
      </Navbar>
    </NavbarVerticalCollapseProvider>
  );
};

export default NavbarVertical;
