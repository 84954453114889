import AppProvider from 'providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { Provider } from 'react-redux';
import store from './redux/store';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { router } from 'Routes';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import OAuth2Provider from './OAuthProvider'; // The new OAuth Provider




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <OAuth2Provider>
        <AppProvider>
          <SettingsPanelProvider>
            <ChatWidgetProvider>
              <BreakpointsProvider>
                <RouterProvider router={router} />
              </BreakpointsProvider>
            </ChatWidgetProvider>
          </SettingsPanelProvider>
        </AppProvider>
      </OAuth2Provider>
      </I18nextProvider>
    </React.StrictMode>
  </Provider>
);
