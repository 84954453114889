import { createSlice } from '@reduxjs/toolkit';
import { fetchUserData, login, signOut } from './authThunk';
import {getToken, removeToken, setToken, getUserData, removeUserData, setUserData} from '../../utils/HelperFunctions';
import api from '../../services/api'

const initialState = {
    token: getToken() || null,
    loading: false,
    userData: JSON.parse(localStorage.getItem('userData')) || null,
    error: null,
    loginInProgress: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            const { token, userData } = action.payload;
            state.token = token;
            // state.userData = userData;
            state.loading = false;
            state.error = null;
            setToken(token);
            // setUserData(JSON.stringify(userData));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false;
                state.userData = {};
                state.token = null;
                state.tokenData = null;
                state.idToken = null;
                state.idTokenData = null;
                state.error = null;
            })
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.token = null;
                state.tokenData = null;
                state.idToken = null;
                state.idTokenData = null;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                const { token, tokenData, idToken, idTokenData } = action.payload;
                state.token = token;
                state.tokenData = tokenData;
                state.idToken = idToken;
                state.idTokenData = idTokenData;
                state.loading = false;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchUserData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                const { ...userData } = action.payload;
                // state.token = token;
                // state.tokenData = tokenData;
                // state.idToken = idToken;
                // state.idTokenData = idTokenData;
                state.userData = userData;
                state.loading = false;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.loading = false;
                state.userData = {};
                state.token = null;
                state.tokenData = null;
                state.idToken = null;
                state.idTokenData = null;
                state.error = action.error.message;
            });
    },
});

export const { loginSuccess } = authSlice.actions;

export default authSlice.reducer;
