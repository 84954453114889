import SignInForm from 'components/modules/auth/SignInForm';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';

import { useState, useEffect } from 'react';
import { getBackendUrl } from '../../../../../src/services/backendUrl';

const SignIn = () => {

  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const checkLoginStatus = async () => {
      // Fetch the backend URL
      const baseURL = await getBackendUrl();

      // Fetch the user username and token from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      console.log('User: ' + JSON.stringify(user));

      // If the token/username does not exist, mark the user as logged out
      if (!user || !user.token) {
        setLoggedIn(false);
        return;
      }

      // If the token exists, verify it with the auth server to see if it is valid
      fetch(baseURL + '/users/validatetoken', {
        method: 'POST',
        headers: {
          'Authorization': user.token,
        },
      })
        .then((r) => r.json())
        .then((r) => {
          setLoggedIn('success' === r.message);
          setUsername(user.username || '');
        })
        .catch((error) => {
          console.error('Error validating token:', error);
          setLoggedIn(false);
        });
    };

    checkLoginStatus();
  }, []);

  console.log('Signing page loggedIn: ' + loggedIn);

  function UserLogin() {
    if (loggedIn) {
      return (
        <span>
          <h1>Already logged in</h1> {'\n'} <a href='link'>Log off</a>
        </span>
      );
    } else {
      return <SignInForm layout='simple' loggedIn={loggedIn} />;
    }
  }

  return (
    <AuthSimpleLayout>
      <UserLogin />
    </AuthSimpleLayout>
  );
};

export default SignIn;
