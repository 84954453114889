import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/base/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { updateConfig, createConfig } from '../../../../redux/slices/configSlice';
import WorkerListWithPriority from '../../../../components/nors/WorkerListWithPriority'; // Adjust the path as needed
import { Worker } from 'components/common/types'; // Ensure this is the correct path

interface EditConfigItemProps {
  configIndex: number | null;  // Pass the index of the config
  isNew?: boolean;
  handleClose: () => void;
}

const EditConfigItem: React.FC<EditConfigItemProps> = ({ configIndex, isNew = false, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  // Initialize the selectedKey from the existing config or set to an empty string for new
  const currentConfig = useSelector((state: RootStateOrAny) =>
    configIndex !== null ? state.config.items[configIndex] : null
  );
  const [selectedKey, setSelectedKey] = useState<string>(isNew ? '' : currentConfig?.key || '');
  const [jsonValid, setJsonValid] = useState<boolean>(true); // State for JSON validation
  const [formData, setFormData] = useState<any>(currentConfig?.value || {}); // Initialize with current config value or empty object
  const [selectedWorkers, setSelectedWorkers] = useState<Worker[]>(currentConfig?.value?.workers || []);

  useEffect(() => {
    if (!isNew && currentConfig) {
      // Initialize form data with the existing value for the config item
      setFormData(currentConfig?.value || {});
    }
  }, [currentConfig, isNew]);

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (!jsonValid) return; // Prevent saving if the JSON is invalid
    const updatedConfig = {
      key: selectedKey,
      value: { ...formData, workers: selectedWorkers }, // Include selected workers in the config value
    };
    if (isNew) {
      dispatch(createConfig(updatedConfig));
    } else {
      dispatch(updateConfig({ config_id: currentConfig.config_id, ...updatedConfig }));
    }
    handleClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleJSONChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    try {
      const parsedValue = JSON.parse(value);
      setFormData(parsedValue); // Update formData with the parsed JSON
      setJsonValid(true); // Set JSON as valid
    } catch (err) {
      setJsonValid(false); // Set JSON as invalid
    }
  };

  const renderSMTPFields = () => {
    return (
      <>
        <Form.Group as={Row} className="mb-3" controlId="smtpHost">
          <Form.Label column sm="2">Host</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="host"
              placeholder={t('enter') + " host"}
              value={formData?.host || ''} // Use the formData state to retain entered values
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="smtpName">
          <Form.Label column sm="2">{t('name')}</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="name"
              placeholder={t('enter') + ' ' + t('name')}
              value={formData?.name || ''} // Use the formData state to retain entered values
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="smtpPort">
          <Form.Label column sm="2">{t('port')}</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="port"
              placeholder={t('enter') + ' ' + t('port')}
              value={formData?.port || ''} // Use the formData state to retain entered values
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="smtpEncryption">
          <Form.Label column sm="2">{t('encryption')}</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="encryption"
              placeholder={t('enter') + ' ' + t('encryption')}
              value={formData?.encryption || ''} // Use the formData state to retain entered values
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="smtpSecurity">
          <Form.Label column sm="2">{t('security')}</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="security"
              placeholder={t('enter') + ' ' + t('security')}
              value={formData?.security || ''} // Use the formData state to retain entered values
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="smtpUsername">
          <Form.Label column sm="2">{t('login.username')}</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="username"
              placeholder={t('enter') + ' ' + t('login.username')}
              value={formData?.username || ''} // Use the formData state to retain entered values
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="smtpPassword">
          <Form.Label column sm="2">{t('login.password')}</Form.Label>
          <Col sm="10">
            <Form.Control
              type="password"
              name="password"
              placeholder={t('enter') + ' ' + t('login.password')}
              value={formData?.password ? '••••••••' : ''}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>
        <WorkerListWithPriority
          selectedWorkers={selectedWorkers}
          setSelectedWorkers={setSelectedWorkers}
        />
      </>
    );
  };

  const renderGeneralFields = () => {
    return (
      <>
        <Form.Group as={Row} className="mb-3" controlId="generalKey">
          <Form.Label column sm="2">{t('key')}</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="key"
              placeholder={t('enter-here')}
              value={selectedKey}
              onChange={(e) => setSelectedKey(e.target.value)} // Allow the key to change without affecting other fields
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="generalValue">
          <Form.Label column sm="2">{t('value')}</Form.Label>
          <Col sm="10">
            <Form.Control
              as="textarea"
              rows={5}
              name="json"
              placeholder="Enter JSON object"
              onChange={handleJSONChange} // Handle JSON input change
              isInvalid={!jsonValid} // Add invalid class if JSON is not valid
              defaultValue={JSON.stringify(formData, null, 2)} // Show JSON representation of formData
            />
            {/* <Form.Control.Feedback type="invalid">
              {t('invalid')} JSON {t('format')}
            </Form.Control.Feedback> */}
            <Form.Text className={jsonValid ? 'text-success' : 'text-danger'}>
              {jsonValid ? (
                <>
                  <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                  {t('valid')} JSON {t('format')}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                  {t('invalid')} JSON {t('format')}
                </>
              )}
            </Form.Text>
          </Col>
        </Form.Group>
      </>
    );
  };

  return (
    <Modal show={true} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{isNew ? t('button.newe') + ' ' + t('menu.configuration') + ' ' + t('key') : t('button.edit') + ' ' + t('menu.configuration') + ' ' + t('key')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isNew ? (
          <>
            <Form.Group as={Row} className="mb-3" controlId="formConfigKey">
              <Form.Label column sm="2">Config {t('key')}</Form.Label>
              <Col sm="10">
                <Form.Control type="text" defaultValue={currentConfig?.key} plaintext readOnly />
              </Col>
            </Form.Group>
            {selectedKey === 'smtp' ? renderSMTPFields() : renderGeneralFields()}
          </>
        ) : (
          <>
            <Form.Group as={Row} className="mb-3" controlId="configType">
              <Form.Label column sm="2">Config Type</Form.Label>
              <Col sm="10">
                <Form.Control as="select" value={selectedKey} onChange={(e) => setSelectedKey(e.target.value)}>
                  <option value="">{t('select')} {t('menu.configuration')} Type</option>
                  <option value="smtp">SMTP</option>
                  <option value="general">{t('general')}</option>
                </Form.Control>
              </Col>
            </Form.Group>
            {selectedKey === 'smtp' ? renderSMTPFields() : renderGeneralFields()} {/* Render form fields based on selected type */}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="phoenix-danger" onClick={handleClose} startIcon={<FontAwesomeIcon icon={faWindowClose} />}>
          {t('button.close')}
        </Button>
        <Button variant="phoenix-primary" onClick={handleSave} startIcon={<FontAwesomeIcon icon={faSave} />} disabled={!jsonValid}>
          {t('button.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditConfigItem;
