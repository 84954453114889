import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWorkers } from '../../redux/slices/workersSlice'; // Adjust the path as needed
import { Worker } from 'components/common/types'; // Adjust the path as needed

interface WorkerListWithPriorityProps {
    selectedWorkers: Worker[]; // Array of selected workers
    setSelectedWorkers: (workers: Worker[]) => void; // Callback to update the selected workers
  }
  

const WorkerListWithPriority: React.FC<WorkerListWithPriorityProps> = ({ selectedWorkers, setSelectedWorkers }) => {
  const dispatch = useDispatch();
  const workers = useSelector((state: any) => state.workers.items);

  useEffect(() => {
    dispatch(fetchWorkers()); // Fetch the list of workers from the Redux store
  }, [dispatch]);

  const handleWorkerChange = (worker: Worker, isChecked: boolean) => {
    if (isChecked) {
      // Add the worker with a default priority of 0
      setSelectedWorkers([...selectedWorkers, { ...worker, priority: 0 }]);
    } else {
      // Remove the worker from the selected list
      setSelectedWorkers(selectedWorkers.filter(w => w.worker_id !== worker.worker_id));
    }
  };

  const handlePriorityChange = (worker: Worker, priority: number) => {
    // Update the priority of the selected worker
    const updatedWorkers = selectedWorkers.map(w =>
      w.worker_id === worker.worker_id ? { ...w, priority } : w
    );
    setSelectedWorkers(updatedWorkers);
  };

  return (
    <Form.Group controlId="workers">
      <Form.Label>Workers</Form.Label>
      {workers.map((worker: Worker) => (
        <div key={worker.worker_id} className="mb-2">
          <Form.Check
            type="checkbox"
            label={worker.worker_name}
            checked={selectedWorkers.some(w => w.worker_id === worker.worker_id)}
            onChange={(e) => handleWorkerChange(worker, e.target.checked)}
          />
          {selectedWorkers.some(w => w.worker_id === worker.worker_id) && (
            <Form.Control
              type="number"
              value={
                selectedWorkers.find(w => w.worker_id === worker.worker_id)?.priority || 0
              }
              onChange={(e) => handlePriorityChange(worker, parseInt(e.target.value, 10))}
              placeholder="Priority"
            />
          )}
        </div>
      ))}
    </Form.Group>
  );
};

export default WorkerListWithPriority;
