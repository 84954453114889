import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, updateUser } from '../../../../redux/slices/userSlice';
import { Form, Button, Row, Col } from 'react-bootstrap';
import PasswordChecklist from 'react-password-checklist';
import { RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';

interface EditUserProps {
  userId: number | null;
  closeModal: () => void;
}

const EditUser = ({ userId, closeModal }: EditUserProps) => {
    const { t } = useTranslation();
  const dispatch = useDispatch();
  const { users } = useSelector((state: RootState) => state.user);

  // Find existing user if editing
  const existingUser = userId ? users.find((user) => user.user_id === userId) : null;

  // State variables
  const [username, setUsername] = useState(existingUser?.username || '');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [mail, setMail] = useState(existingUser?.mail || '');
  const [home_folder, setHome_folder] = useState(existingUser?.home_folder || '');
  const [public_key, setPublic_key] = useState(existingUser?.public_key || '');
  const [userConfig, setUserConfig] = useState({
    allow_connection_via: {
      web_ui: existingUser?.user_config?.allow_connection_via?.web_ui || false,
      webdav: existingUser?.user_config?.allow_connection_via?.webdav || false,
      rest: existingUser?.user_config?.allow_connection_via?.rest || false,
      as2: existingUser?.user_config?.allow_connection_via?.as2 || false,
      ftp: existingUser?.user_config?.allow_connection_via?.ftp || false,
      sftp: existingUser?.user_config?.allow_connection_via?.sftp || false,
    },
  });

  const handleSave = () => {
    const userPayload = {
      username,
      password: password ? password : undefined, // Only include password if it's set
      mail,
      home_folder,
      public_key,
      user_config: userConfig,
    };

    if (userId) {
      dispatch(updateUser({ ...userPayload, user_id: userId }));
    } else {
      dispatch(createUser(userPayload));
    }

    closeModal(); // Close the modal after saving
  };

  const handleConfigChange = (key: string) => {
    setUserConfig((prevConfig) => ({
      ...prevConfig,
      allow_connection_via: {
        ...prevConfig.allow_connection_via,
        [key]: !prevConfig.allow_connection_via[key],
      },
    }));
  };

  return (
    <Form>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">{t('login.username')}</Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">{t('login.username')}</Form.Label>
        <Col sm="10">
          <Form.Control
            type="email"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">Password</Form.Label>
        <Col sm="10">
          <Form.Control
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">Password Again</Form.Label>
        <Col sm="10">
          <Form.Control
            type="password"
            onChange={(e) => setPasswordAgain(e.target.value)}
          />
        </Col>
      </Form.Group>

      <PasswordChecklist
        rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
        minLength={5}
        value={password}
        valueAgain={passwordAgain}
        onChange={(isValid) => {}}
      />

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">Home Folder</Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            value={home_folder}
            onChange={(e) => setHome_folder(e.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">Public Key</Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            value={public_key}
            onChange={(e) => setPublic_key(e.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">Allow Connection via</Form.Label>
        <Col sm="10">
          <Form.Check
            type="checkbox"
            label="Web UI"
            checked={userConfig.allow_connection_via.web_ui}
            onChange={() => handleConfigChange('web_ui')}
          />
          <Form.Check
            type="checkbox"
            label="Webdav"
            checked={userConfig.allow_connection_via.webdav}
            onChange={() => handleConfigChange('webdav')}
          />
          <Form.Check
            type="checkbox"
            label="REST"
            checked={userConfig.allow_connection_via.rest}
            onChange={() => handleConfigChange('rest')}
          />
          <Form.Check
            type="checkbox"
            label="AS2"
            checked={userConfig.allow_connection_via.as2}
            onChange={() => handleConfigChange('as2')}
          />
          <Form.Check
            type="checkbox"
            label="FTP"
            checked={userConfig.allow_connection_via.ftp}
            onChange={() => handleConfigChange('ftp')}
          />
          <Form.Check
            type="checkbox"
            label="SFTP"
            checked={userConfig.allow_connection_via.sftp}
            onChange={() => handleConfigChange('sftp')}
          />
        </Col>
      </Form.Group>

      <Button variant="primary" onClick={handleSave}>
        {userId ? 'Update User' : 'Create User'}
      </Button>
    </Form>
  );
};

export default EditUser;
