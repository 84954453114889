import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../services/api';

// Define types for flow parameters
interface FlowParameter {
  name: string;
  type: string;
  default_value: any;
}

// Define types for flow
interface Flow {
  flow_id: number;
  flow_name: string;
  flow_definition: string;
  flow_nodes: any;
  flow_edges: any;
  parameters: FlowParameter[];
}

// Define types for flow instance
interface FlowInstance {
  instance_id: number;
  flow_id: number;
  flow_name: string;
  status: string;
  created_at: string;
}

// Define the state type
interface FlowsState {
  flows: Flow[];
  currentFlow: Flow | null;
  flowInstances: FlowInstance[];
  currentFlowInstance: FlowInstance | null;
  loading: boolean;
  error: string | null;
  errorCode: number | null;
}

// Initial state
const initialState: FlowsState = {
  flows: [],
  currentFlow: null,
  flowInstances: [],
  currentFlowInstance: null,
  loading: false,
  error: null,
  errorCode: null,
};

// Thunks for async operations
export const fetchFlows = createAsyncThunk<Flow[], void, { rejectValue: { message: string; statusCode?: number } }>(
  'flows/fetchFlows',
  async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('/flows');
    return response.data.map((flow: any) => ({
      ...flow,
      parameters: flow.flow_parameters.map((param: any) => ({
        name: param.flow_parameter_name,
        type: param.flow_parameter_type,
        default_value: param.flow_parameter_default_value
      }))
    }));
  } catch (error: any) {
    return rejectWithValue({
      message: error.response?.data?.message || 'Failed to fetch flows',
      statusCode: error.response?.status,
    });
  }
});

export const fetchFlowById = createAsyncThunk<Flow, number>('flows/fetchFlowById', async (flow_id) => {
  const response = await api.get(`/flows/${flow_id}`);
  const flow = response.data;
  return {
    ...flow,
    parameters: flow.flow_parameters.map((param: any) => ({
      name: param.flow_parameter_name,
      type: param.flow_parameter_type,
      default_value: param.flow_parameter_default_value
    }))
  };
});

export const createFlow = createAsyncThunk<Flow, Partial<Flow>>('flows/createFlow', async (flow) => {
  const response = await api.post('/flows', flow);
  return {
    ...response.data,
    parameters: response.data.flow_parameters.map((param: any) => ({
      name: param.flow_parameter_name,
      type: param.flow_parameter_type,
      default_value: param.flow_parameter_default_value
    }))
  };
});

export const updateFlow = createAsyncThunk<Flow, Flow>('flows/updateFlow', async (flow) => {
  const response = await api.put(`/flows/${flow.flow_id}`, flow);
  return {
    ...response.data,
    parameters: response.data.flow_parameters.map((param: any) => ({
      name: param.flow_parameter_name,
      type: param.flow_parameter_type,
      default_value: param.flow_parameter_default_value
    }))
  };
});

export const deleteFlow = createAsyncThunk<number, number>('flows/deleteFlow', async (flow_id) => {
  await api.delete(`/flows/${flow_id}`);
  return flow_id; // Return flow_id to use in reducer for removing from state
});



// Thunk for canceling a single occurrence
export const cancelOccurrence = createAsyncThunk<{ instance_id: number }, number>(
  'flows/cancelOccurrence',
  async (instance_id) => {
    const response = await api.post(`/flowinstances/${instance_id}/canceloccurrence`);
    return { instance_id };
  }
);

// Thunk for canceling a series for a single flow instance
export const cancelSeries = createAsyncThunk<{ instance_id: number }, number>(
  'flows/cancelSeries',
  async (instance_id) => {
    const response = await api.post(`/flowinstances/${instance_id}/cancelseries`);
    return { instance_id };
  }
);

// Thunk for canceling multiple occurrences (batch cancellation)
export const cancelOccurrences = createAsyncThunk<
  { updatedFlowInstances: { instance_id: number; status: string }[], newFlowInstances: any[] },
  { instance_ids: number[] }
>(
  'flows/cancelOccurrences',
  async ({ instance_ids }) => {
    const response = await api.post(`/flowinstances/canceloccurrences`, { instance_ids });

    // Return updated flow instances and any new flow instances created
    return {
      updatedFlowInstances: response.data.updatedFlowInstances.map((instance: any) => ({
        instance_id: instance.instance_id,
        status: instance.status
      })),
      newFlowInstances: response.data.newFlowInstances
    };
  }
);



// Thunk for canceling multiple series (batch cancellation)
export const cancelSeriesBatch = createAsyncThunk<
  { updatedFlowInstances: { instance_id: number; status: string }[], newFlowInstances: any[] },
  { instance_ids: number[] }
>(
  'flows/cancelSeriesBatch',
  async ({ instance_ids }) => {
    const response = await api.post(`/flowinstances/cancelseries`, { instance_ids });

    // Return updated flow instances and new flow instances if any
    return {
      updatedFlowInstances: response.data.updatedFlowInstances.map((instance: any) => ({
        instance_id: instance.instance_id,
        status: instance.status
      })),
      newFlowInstances: response.data.newFlowInstances
    };
  }
);





// Thunks for flow instances
export const fetchFlowInstances = createAsyncThunk<
  FlowInstance[],
  { limit?: number; status?: string[]; sort_by?: string; flow_name?: string }
>(
  'flows/fetchFlowInstances',
  async ({ limit = 10, status = [], sort_by = 'created_at', flow_name } = {}) => {
    // Construct the body object, conditionally adding flow_name if it's provided
    const body: any = {
      limit,
      status,
      sort_by,
    };

    // Add flow_name to the body if provided
    if (flow_name) {
      body.flow_name = flow_name;
    }

    // Make POST request with the body parameters
    const response = await api.post('/flowsinstances', body);
    return response.data;
  }
);




export const fetchFlowInstanceById = createAsyncThunk<FlowInstance, number>('flows/fetchFlowInstanceById', async (instance_id) => {
  const response = await api.get(`/flowsinstances/${instance_id}`);
  return response.data;
});

export const deleteFlowInstance = createAsyncThunk<number, number>('flows/deleteFlowInstance', async (instance_id) => {
  await api.delete(`/flowsinstances/${instance_id}`);
  return instance_id; // Return instance_id to use in reducer for removing from state
});

export const runFlow = createAsyncThunk<{ instance_id: number }, { flow_id: number; scheduleDetails?: any }>(
  'flows/runFlow',
  async ({ flow_id, scheduleDetails = null }) => {
    const response = await api.post(`/scheduler/schedule/${flow_id}`, scheduleDetails || {});
    return { instance_id: response.data.instance_id }; // Assuming the response contains the flow_instance_id
  }
);


// Slice
const flowsSlice = createSlice({
  name: 'flows',
  initialState,
  reducers: {
    clearCurrentFlow: (state) => {
      state.currentFlow = null;
    },
    clearCurrentFlowInstance: (state) => {
      state.currentFlowInstance = null;
    },
  },
  extraReducers: (builder) => {
    builder
    // Fetch Flows
    .addCase(fetchFlows.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchFlows.fulfilled, (state, action: PayloadAction<Flow[]>) => {
      state.flows = action.payload;
      state.loading = false;
      state.error = null;
    })
    .addCase(fetchFlows.rejected, (state, action) => {
      state.loading = false;
      state.flows = [];
      if (action.payload) {
        // Handle custom rejected value with statusCode
        state.error = `Error ${action.payload.statusCode}: ${action.payload.message}`;
        state.errorCode = action.payload.statusCode
      } else {
        // Handle unknown errors
        state.error = action.error.message || 'Failed to fetch flows';
      }
    })

      // Fetch Flow by ID
      .addCase(fetchFlowById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFlowById.fulfilled, (state, action: PayloadAction<Flow>) => {
        state.currentFlow = action.payload;
        state.loading = false;
      })
      .addCase(fetchFlowById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch flow';
      })

      // Create Flow
      .addCase(createFlow.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFlow.fulfilled, (state, action: PayloadAction<Flow>) => {
        state.flows.push(action.payload);
        state.currentFlow = action.payload;
        state.loading = false;
      })
      .addCase(createFlow.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create flow';
      })

      // Update Flow
      .addCase(updateFlow.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFlow.fulfilled, (state, action: PayloadAction<Flow>) => {
        const index = state.flows.findIndex((flow) => flow.flow_id === action.payload.flow_id);
        if (index !== -1) {
          state.flows[index] = action.payload;
        }
        state.currentFlow = action.payload;
        state.loading = false;
      })
      .addCase(updateFlow.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update flow';
      })

      // Delete Flow
      .addCase(deleteFlow.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFlow.fulfilled, (state, action: PayloadAction<number>) => {
        state.flows = state.flows.filter((flow) => flow.flow_id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteFlow.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to delete flow';
      })

      // Fetch Flow Instances
      .addCase(fetchFlowInstances.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFlowInstances.fulfilled, (state, action: PayloadAction<FlowInstance[]>) => {
        state.flowInstances = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchFlowInstances.rejected, (state, action) => {
        state.loading = false;
        state.flowInstances = [];
        state.error = action.error.message || 'Failed to fetch flow instances';
      })

      // Fetch Flow Instance by ID
      .addCase(fetchFlowInstanceById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFlowInstanceById.fulfilled, (state, action: PayloadAction<FlowInstance>) => {
        state.currentFlowInstance = action.payload;
        state.loading = false;
      })
      .addCase(fetchFlowInstanceById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch flow instance';
      })

      // Run Flow
      .addCase(runFlow.pending, (state) => {
        state.loading = true;
      })
      .addCase(runFlow.fulfilled, (state, action: PayloadAction<{ instance_id: number }>) => {
        state.loading = false;
      })
      .addCase(runFlow.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to run flow';
      })

      // Delete Flow Instance
      .addCase(deleteFlowInstance.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFlowInstance.fulfilled, (state, action: PayloadAction<number>) => {
        state.flowInstances = state.flowInstances.filter((instance) => instance.instance_id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteFlowInstance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to delete flow instance';
      })
      // Cancel Occurrence (Single)
      .addCase(cancelOccurrence.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelOccurrence.fulfilled, (state, action: PayloadAction<{ instance_id: number }>) => {
        state.flowInstances = state.flowInstances.filter(
          (instance) => instance.instance_id !== action.payload.instance_id
        );
        state.loading = false;
      })
      .addCase(cancelOccurrence.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to cancel occurrence';
      })

      // Cancel Series (Single)
      .addCase(cancelSeries.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelSeries.fulfilled, (state, action: PayloadAction<{ instance_id: number }>) => {
        state.flowInstances = state.flowInstances.filter(
          (instance) => instance.instance_id !== action.payload.instance_id
        );
        state.loading = false;
      })
      .addCase(cancelSeries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to cancel series';
      })

// Cancel Occurrences (Batch)
.addCase(cancelOccurrences.pending, (state) => {
  state.loading = true;
})
.addCase(cancelOccurrences.fulfilled, (state, action: PayloadAction<{ updatedFlowInstances: { instance_id: number; status: string }[], newFlowInstances: any[] }>) => {
  // Update the status of canceled instances
  action.payload.updatedFlowInstances.forEach((updatedInstance) => {
    const instanceIndex = state.flowInstances.findIndex(
      (instance) => instance.instance_id === updatedInstance.instance_id
    );

    if (instanceIndex !== -1) {
      // Update the status of the existing instance to 'cancelled'
      state.flowInstances[instanceIndex].status = updatedInstance.status;
    }
  });

  // Add new flow instances to the existing ones (without overwriting)
  if (action.payload.newFlowInstances.length > 0) {
    state.flowInstances.push(...action.payload.newFlowInstances);
  }

  state.loading = false;
})
.addCase(cancelOccurrences.rejected, (state, action) => {
  state.loading = false;
  state.error = action.error.message || 'Failed to cancel occurrences';
})



// Cancel Series (Batch)
.addCase(cancelSeriesBatch.pending, (state) => {
  state.loading = true;
})
.addCase(cancelSeriesBatch.fulfilled, (state, action: PayloadAction<{ updatedFlowInstances: { instance_id: number; status: string }[], newFlowInstances: any[] }>) => {
  // Update the status of canceled series instances
  action.payload.updatedFlowInstances.forEach((updatedInstance) => {
    const instanceIndex = state.flowInstances.findIndex(
      (instance) => instance.instance_id === updatedInstance.instance_id
    );

    if (instanceIndex !== -1) {
      // Update the status of the existing instance to 'cancelled'
      state.flowInstances[instanceIndex].status = updatedInstance.status;
    }
  });

  // Add new flow instances to the existing ones (without overwriting)
  if (action.payload.newFlowInstances.length > 0) {
    state.flowInstances.push(...action.payload.newFlowInstances);
  }

  state.loading = false;
})
.addCase(cancelSeriesBatch.rejected, (state, action) => {
  state.loading = false;
  state.error = action.error.message || 'Failed to cancel series';
});

  },
});

export const { clearCurrentFlow, clearCurrentFlowInstance } = flowsSlice.actions;

export default flowsSlice.reducer;
