import { createAsyncThunk } from '@reduxjs/toolkit'
import {getToken, removeToken, removeUserData, setToken} from '../../utils/HelperFunctions';
import api from '../../services/api';
import history from '../../utils/history';
import { useNavigate } from 'react-router-dom'

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async (_, {rejectWithValue}) => {
    try{
        const accessToken = getToken();
        console.log("redux accesstoken: "+accessToken)
        api.defaults.headers.Authorization = `Bearer ${accessToken}`;
        const response = await api.post('/auth/validatetoken');
        return {...response.data, accessToken};
    }catch(e){
        removeToken();
        return rejectWithValue('');
    }
});

export const login = createAsyncThunk('auth/login', async (payload: object) => {
    const response = await api.post('/users/login', payload);
    setToken(response.data.token);
    api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
    // history.push('/');

    return response.data;
});

export const signOut = createAsyncThunk('auth/signOut', async () => {
    removeToken();
    removeUserData();
});