import React, { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { fetchConfigs, deleteConfig } from '../../../../redux/slices/configSlice';  // Import deleteConfig
import SearchBox from 'components/common/SearchBox';  // Assuming you have a reusable SearchBox component
import EditConfigItem from './editConfigItem';  // Import the EditConfigItem component

const ListConfig = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const configs = useSelector((state: any) => state.config.items);

    const [showModal, setShowModal] = useState(false);  // Controls modal visibility
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);  // Tracks the index of the current config being edited
    const [isNew, setIsNew] = useState(false);  // Determines if it's a new config creation
    const [filteredConfigs, setFilteredConfigs] = useState(configs);  // To manage search filtering
    const [searchTerm, setSearchTerm] = useState<string>('');  // State for search term

    // Fetch all configs on component mount
    useEffect(() => {
        dispatch(fetchConfigs());
    }, [dispatch]);

    useEffect(() => {
        setFilteredConfigs(configs);
    }, [configs]);

    // Handle search input change
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.length > 0) {
            const filtered = configs.filter((config: any) =>
                config.key.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredConfigs(filtered);
        } else {
            setFilteredConfigs(configs);  // Reset if search term is cleared
        }
    };

    // Handle "New" button click to create a new config
    const handleNew = () => {
        setCurrentIndex(null);  // No config index for new creation
        setIsNew(true);  // Indicate we're creating a new config
        setShowModal(true);  // Show the modal
    };

    // Handle edit button click to reference specific config details by index
    const handleEdit = (index: number) => {
        setCurrentIndex(index);  // Set the index of the current config to edit
        setIsNew(false);  // Indicate we're editing an existing config
        setShowModal(true);
    };

    // Handle delete config
    const handleDelete = (config_id: number) => {
        dispatch(deleteConfig(config_id));  // Dispatch deleteConfig action with the config_id
    };

    return (
        <Container fluid className="p-0">
            {/* Search bar and New button */}
            <Row className="m-0 mb-4">
                <Col className="p-0 d-flex justify-content-between align-items-center">
                    <SearchBox
                        placeholder={t('search') + "..."}
                        size="sm"
                        onChange={handleSearch}
                    />
                    <Button variant="outline-primary" size="sm" onClick={handleNew}>
                        {t('button.new')}
                    </Button>
                </Col>
            </Row>

            <Row className="m-0">
                <Col className="p-0">
                    {/* Table */}
                    <table className="table table-hover table-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t('key')}</th>
                                <th>{t('action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredConfigs.map((config: any, i: number) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{config.key}</td>
                                    <td>
                                        <Button variant="outline-secondary" onClick={() => handleEdit(i)}>
                                            {t('button.edit')}
                                        </Button>{' '}
                                        <Button variant="outline-danger" onClick={() => handleDelete(config.config_id)}>
                                            {t('button.delete')}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Modal for editing or creating config */}
                    {showModal && (
                        <EditConfigItem
                            configIndex={currentIndex}  // Pass the index for editing
                            isNew={isNew}  // Indicate whether it's a new config creation or editing
                            handleClose={() => setShowModal(false)}  // Handle modal close
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default ListConfig;
