import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type ActionDropdownFlowsProps = {
  flow_id: number;
  onAction: (flow_id: number, action: string) => void;
};

const ActionDropdownFlows: React.FC<ActionDropdownFlowsProps> = ({ flow_id, onAction }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* <Dropdown.Item eventKey="1" onClick={() => onAction(block_id, 'edit')}>Edit</Dropdown.Item> */}
      <Dropdown.Item eventKey="1" onClick={() => onAction(flow_id, 'edit')}>{t('button.edit')}</Dropdown.Item>
      <Dropdown.Item eventKey="2" onClick={() => onAction(flow_id, 'run')}>{t('button.run')}</Dropdown.Item>
      <Dropdown.Item eventKey="3" onClick={() => onAction(flow_id, 'schedule')}>{t('schedule')}</Dropdown.Item>
      {/* <Dropdown.Item eventKey="2" onClick={() => onAction(flow_id, 'edit_popout')}>Edit</Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item eventKey="4" className="text-danger" onClick={() => onAction(flow_id, 'remove')}>
        {t('button.delete')}
      </Dropdown.Item>
    </>
  );
};

export default ActionDropdownFlows;
