import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, deleteUser } from '../../../../redux/slices/userSlice';
import { Button, Table, Spinner, Modal, Container, Row, Col, ButtonGroup } from 'react-bootstrap';
import EditUser from './editUser';
import { RootState } from '../../../../redux/store'; // Ensure RootState is imported
import { useTranslation } from 'react-i18next';

const ListUsers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { users, loading } = useSelector((state: RootState) => state.user);

    // State to handle modal visibility
    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const handleNewUser = () => {
        setSelectedUserId(null); // For new user, pass null
        setShowModal(true);
    };

    const handleEditUser = (userId: number) => {
        setSelectedUserId(userId);
        setShowModal(true);
    };

    const handleDeleteUser = (userId: number) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            dispatch(deleteUser(userId));
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUserId(null); // Reset selected user
    };

    if (loading) {
        return <Spinner animation="border" />;
    }

    return (
        <Container fluid className="p-0">
            {/* Search bar and New button */}
            <Row className="m-0 mb-4">
                <Col className="p-0 d-flex justify-content-between align-items-center">
                    <h2>User List</h2>
                    <Button variant="outline-primary" onClick={handleNewUser}>{t('button.new')}</Button>
                    </Col>
            </Row>

            <Row className="m-0">
                <Col className="p-0">
                <Table hover size='sm' >
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.user_id}>
                                    <td>{user.user_id}</td>
                                    <td>{user.username}</td>
                                    <td>{user.mail}</td>
                                    <td>
                                    <ButtonGroup aria-label="Basic example">
                                        <Button variant="outline-secondary" onClick={() => handleEditUser(user.user_id)}>
                                            {t('button.edit')}
                                        </Button>{' '}
                                        <Button variant="outline-danger" onClick={() => handleDeleteUser(user.user_id)}>
                                        {t('button.delete')}
                                        </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* Modal to handle Edit and New user */}
                    <Modal show={showModal} onHide={handleCloseModal} size="lg" backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>{selectedUserId ? 'Edit User' : 'New User'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EditUser userId={selectedUserId} closeModal={handleCloseModal} />
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
        </Container>
    );
};

export default ListUsers;
