import React from 'react';
import { Handle, Position } from 'reactflow';
import iconWorkflow from '../../../../../assets/img/icons/user.png';

const icons = {
  workflow: iconWorkflow
};

const Icon = ({ iconname }) => (
  <img 
    src={iconname}
    alt="icon" 
    style={{ maxWidth: '20px', height: '20px' }} 
  />
);
const WorkflowNode = ({ id, data }) => {
  const type = "workflow"
  const iconname = icons[type] || iconFile; // Select icon based on type
  // Base height is the initial height of the node, without conditions
  const baseHeight = 28;
  const nodeClass = `dndnode status-${data.status || 'unknown'}`; // Construct dynamic class
  // Calculate total height dynamically based on the number of options
  const totalHeight = baseHeight + (data.options?.length || 1) * 27;

  return (
    <div className={nodeClass} style={{ height: `${totalHeight}px` }}>
      <div><Icon iconname={iconname} /> {data.label}</div>
      <div style={{ fontSize: '0.75em' }}>ID: {id}</div>
      <Handle type="target" position={Position.Left} />

      {data.options?.map((option, index) => {
        // Check if the option ID is in activated_handles
        const isActivated = data.activated_handles?.includes(option.id);

        return (
          <div
            key={`${id}-option-${option.id}`}
            style={{
              position: 'absolute',
              top: `${(index + 1) * 27}px`,
              right: '0px',
              display: 'flex'
            }}
          >
            <Handle
              id={option.id} // Use option ID for handle
              type="source"
              position={Position.Right}
              style={{
                top: 0,
                backgroundColor: isActivated ? '#007a4a' : 'gray',  // Green if activated, otherwise gray
                width: isActivated ? '12px' : '8px',  // Bigger if activated
                height: isActivated ? '12px' : '8px',  // Bigger if activated
                borderRadius: '50%',  // Make sure the handle remains circular
              }}
            />
            <span style={{ fontSize: '0.75em', marginLeft: '10px' }}>{option.key}</span>
          </div>
        );
      })}
    </div>
  );
};

export default WorkflowNode;
