import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

interface TableRow {
    table_id: number;
    table_row_id: number;
    values: object; // Store row values as JSON
}

interface TableRowState {
    items: TableRow[];
    status: string | null;
    currentRow: TableRow | null;
    currentRowStatus: string | null;
}

const initialState: TableRowState = {
    items: [],
    status: null,
    currentRow: null,
    currentRowStatus: null,
};

// Fetch all rows for a specific table
export const fetchTableRows = createAsyncThunk('tableRow/fetchTableRows', async (table_id: number) => {
    const response = await api.get(`/tables/${table_id}/rows`);
    return response.data;
});

// Create a new row in a specific table
export const createTableRow = createAsyncThunk('tableRow/createTableRow', async ({ table_id, values }: Omit<TableRow, 'table_row_id'>) => {
    const response = await api.post(`/tables/${table_id}/rows`, { values });
    return response.data;
});

// Update an existing row in a specific table
export const updateTableRow = createAsyncThunk('tableRow/updateTableRow', async (row: TableRow) => {
    const response = await api.put(`/tables/${row.table_id}/rows/${row.table_row_id}`, row);
    return response.data;
});

// Delete a row in a specific table
export const deleteTableRows = createAsyncThunk('tableRow/deleteTableRows', async ({ table_id, table_row_ids }: { table_id: number, table_row_ids: number[] }) => {
    await api.post(`/tables/${table_id}/rows/delete`, { row_ids: table_row_ids });
    return table_row_ids; // Return the array of deleted row IDs
});


// Table Row Slice
const tableRowSlice = createSlice({
    name: 'tableRow',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch all rows for a table
            .addCase(fetchTableRows.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTableRows.fulfilled, (state, action) => {
                state.items = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchTableRows.rejected, (state) => {
                state.status = 'failed';
            })
            // Create new row
            .addCase(createTableRow.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            // Update row
            .addCase(updateTableRow.fulfilled, (state, action) => {
                const index = state.items.findIndex(
                    (row) => row.table_row_id === action.payload.table_row_id && row.table_id === action.payload.table_id
                );
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            })
            // Delete rows
            // Delete rows
            .addCase(deleteTableRows.fulfilled, (state, action) => {
                // Filter out the deleted rows from the items array
                state.items = state.items.filter(
                    (row) => !action.payload.includes(row.table_row_id)
                );
            })
            // Delete rows - rejected case to handle any errors
            .addCase(deleteTableRows.rejected, (state, action) => {
                state.status = 'failed';  // Set the status to failed
                console.error('Failed to delete rows:', action.error.message); // Log the error message
            })
    },
});

export default tableRowSlice.reducer;
