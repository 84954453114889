import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownFlowsInstances from '../../../../../components/common/ActionDropdownFlowsInstances';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFlowInstances, deleteFlowInstance, cancelOccurrences, cancelSeriesBatch } from '../../../../../redux/slices/flowsSlice';
import { Container, Row, Col, Dropdown, Form, Button, Modal, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import SearchBox from 'components/common/SearchBox';


interface Recurrence {
  interval: string;
  frequency: string;
  days_of_week?: string[];
  days_of_month?: number[];
}

interface FlowInstance {
  instance_id: number;
  flow_id: number;
  flow_name: string;
  status: string;
  created_at: string;
  scheduled_start_datetime: string;
  scheduled_start_timezone: string;
  recurrence: Recurrence;
}

const ListFlowInstances = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const flowInstances = useSelector((state: any) => state.flows.flowInstances);
  const loading = useSelector((state: any) => state.flows.loading);
  const error = useSelector((state: any) => state.flows.error); // Get error from state
  // State to track selected statuses
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  // State to track selected limit
  const [selectedLimit, setSelectedLimit] = useState<number>(50);

  // State to track search term
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  // State for row selection
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  // State for modal
  const [showModal, setShowModal] = useState<boolean>(false);

  const [selectionAction, setSelectionAction] = useState<string | null>(null);


  // Fetch flow instances whenever filters change
  useEffect(() => {
    dispatch(fetchFlowInstances({
      limit: selectedLimit,
      status: selectedStatuses.length ? selectedStatuses : undefined,
      flow_name: searchTerm.length > 3 ? searchTerm : undefined,
    }));
  }, [dispatch, selectedStatuses, selectedLimit, searchTerm]);

  // Handle action when a flow instance is clicked
  const handleAction = (instance_id: number, action: string) => {
    const flowInstance = flowInstances.find((instance: FlowInstance) => instance.instance_id === instance_id);
    if (action === 'view') {
      navigate(`/apps/scheduler/flowinstanceviewer/${flowInstance.instance_id}`);
    } else if (action === 'remove') {
      dispatch(deleteFlowInstance(flowInstance.instance_id));
    }
  };

  // Handle row selection toggle
  const handleRowSelection = (instance_id: number) => {
    setSelectedRowIds((prevSelected) =>
      prevSelected.includes(instance_id)
        ? prevSelected.filter((id) => id !== instance_id) // Deselect
        : [...prevSelected, instance_id] // Select
    );
  };

  // Handle status selection change
  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    const status = e.target.value;
    if (e.target.checked) {
      setSelectedStatuses(prev => [...prev, status]);
    } else {
      setSelectedStatuses(prev => prev.filter(s => s !== status));
    }
  };

  // Handle limit selection change
  const handleLimitChange = (limit: number) => {
    setSelectedLimit(limit);
  };

  // Handle search input change with debouncing
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        if (value.length > 3) {
          setSearchTerm(value);
        } else {
          setSearchTerm('');
        }
      }, 100)
    );
  };

  const handleShowModal = (action: string) => {
    setSelectionAction(action); // Set the action (Cancel Occurrences or Cancel Series)
    setShowModal(true);
  };
  
  const handleCloseModal = () => setShowModal(false);
  
  // Modal confirmation with dynamic action
  const renderModalContent = () => {
    const selectedIds = selectedRowIds.join(', ');
  
    if (selectionAction === 'cancelOccurrences') {
      return (
        <>
          <p>Are you sure to cancel these occurrences: {selectedIds}?</p>
          <div className="d-flex justify-content-end">
            <button className="btn btn-danger" onClick={handleCancelOccurrences}>
              Yes
            </button>
            <button className="btn btn-secondary ms-2" onClick={handleCloseModal}>
              No
            </button>
          </div>
        </>
      );
    } else if (selectionAction === 'cancelSeries') {
      return (
        <>
          <p>Are you sure to cancel these occurrences and their related series: {selectedIds}?</p>
          <div className="d-flex justify-content-end">
            <button className="btn btn-danger" onClick={handleCancelSeriesBatch}>
              Yes
            </button>
            <button className="btn btn-secondary ms-2" onClick={handleCloseModal}>
              No
            </button>
          </div>
        </>
      );
    }
  };
  
// Dispatch cancelOccurrences action
const handleCancelOccurrences = () => {
  dispatch(cancelOccurrences({ instance_ids: selectedRowIds }));
  handleCloseModal();
};

// Dispatch cancelSeriesBatch action
const handleCancelSeriesBatch = () => {
  dispatch(cancelSeriesBatch({ instance_ids: selectedRowIds }));
  handleCloseModal();
};

  
  


  const columns: ColumnDef<FlowInstance>[] = [
    {
      accessorKey: 'select',
      header: ({ table }) => (
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked) {
              const allIds = flowInstances.map((instance: FlowInstance) => instance.instance_id);
              setSelectedRowIds(allIds);
            } else {
              setSelectedRowIds([]);
            }
          }}
          checked={selectedRowIds.length === flowInstances.length}
        />
      ),
      cell: ({ row }) => (
        <input
          type="checkbox"
          checked={selectedRowIds.includes(row.original.instance_id)}
          onChange={() => handleRowSelection(row.original.instance_id)}
        />
      ),
    },
    {
      accessorKey: 'instance_id',
      header: 'Flow ID',
      cell: ({ row }) => (
        <span
          onClick={() => handleAction(row.original.instance_id, 'view')}
          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {row.original.instance_id}
        </span>
      ),
    },
    {
      accessorKey: 'flow_name',
      header: t('flow-name'),
      cell: ({ row }) => (
        <span
          onClick={() => handleAction(row.original.instance_id, 'view')}
          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {row.original.flow_name}
        </span>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'created_at',
      header: t('created_at'),
      cell: ({ row }) => {
        const formattedDate = format(new Date(row.original.created_at), 'dd-MM-yyyy HH:mm:ss');
        return <span>{formattedDate}</span>;
      },
    },
    {
      accessorKey: 'scheduled_start_datetime',
      header: t('scheduled-start-datetime'),
      cell: ({ row }) => {
        const formattedDate = format(new Date(row.original.scheduled_start_datetime), 'dd-MM-yyyy HH:mm:ss');
        return <span>{formattedDate}</span>;
      },
    },
    {
      accessorKey: 'recurrence',
      header: t('recurrence'),
      cell: ({ row }) => {
        const recurrence = row.original.recurrence;
    
        if (recurrence && typeof recurrence === 'object') {
          const days_of_week = recurrence.days_of_week?.length
            ? recurrence.days_of_week.join(', ')
            : null;
          const days_of_month = recurrence.days_of_month?.length
            ? recurrence.days_of_month.join(', ')
            : null;
        
          const interval = Number(recurrence.interval) || 1;
          const frequency = recurrence.frequency || 'week';
        
          return (
            <div>
              {`Every ${interval} ${frequency}${interval > 1 ? 's' : ''}`} {days_of_week ? `(Days of week: ${days_of_week})` : ''} {days_of_month ? `(Days of month: ${days_of_month})` : ''}
            </div>
          );
        }
    
        return <span>{typeof recurrence === 'string' ? recurrence : 'N/A'}</span>;
      },
    },
    {
      id: 'action',
      cell: ({ row }) => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownFlowsInstances flow_instance_id={row.original.instance_id} onAction={handleAction} />
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
    },
  ];

  const table = useAdvanceTable({
    data: flowInstances,
    columns,
    pageSize: 100000,
    pagination: true,
    selection: false,
    sortable: true,
  });

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col className="p-0">
        {error && (
            <Alert variant="outline-danger">
              {error === 'Request failed with status code 403' ? t('error-not-authorized') : t('error-generic')}
            </Alert>
          )}
          <div className="d-flex gap-2 mb-3">
            {selectedRowIds.length > 0 && (
              <Dropdown className="mr-2">
                <Dropdown.Toggle variant="outline-secondary" id="dropdown-selection-action">
                  {t('action')}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleShowModal('cancelSeries')}>
                    {t('cancel-series')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleShowModal('cancelOccurrences')}>
                    {t('cancel-occurrences')}
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            )}
            <SearchBox
              placeholder={t('search')+'...'}
              size="sm"
              onChange={handleSearchInputChange}
              className="mx-auto"
            />
            {/* Status Filter Dropdown */}
            <Dropdown className="mr-2">
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-status">
                Filter {t('by')} Status
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {['finished', 'cancelled', 'pending', 'error', 'aborted'].map(status => (
                  <Form.Check
                    key={status}
                    type="checkbox"
                    label={status}
                    value={status}
                    onChange={handleStatusChange}
                    checked={selectedStatuses.includes(status)}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {/* Limit Dropdown */}
            <Dropdown className="mr-2">
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-limit">
                {t('limit')}: {selectedLimit}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {[50, 100, 500, 1000].map(limit => (
                  <Dropdown.Item key={limit} onClick={() => handleLimitChange(limit)}>
                    {limit}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Table */}
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              tableProps={{
                size: 'sm',
                className: 'phoenix-table fs-9 mb-0 border-top border-translucent',
              }}
              rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            />
            <AdvanceTableFooter navBtn />
          </AdvanceTableProvider>

          {/* Modal */}
          {/* Modal */}
<Modal show={showModal} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>{selectionAction === 'cancelOccurrences' ? 'Cancel Occurrences' : 'Cancel Series'}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {renderModalContent()}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseModal}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

        </Col>
      </Row>
    </Container>
  );
};

export default ListFlowInstances;
