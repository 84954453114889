import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWorkers, createWorker, updateWorker, deleteWorker } from '../../../../redux/slices/workersSlice';
import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup, Modal, Form } from 'react-bootstrap';

interface WorkerFormProps {
  worker_id?: number;
  worker_name?: string;
  worker_secret?: string;
}

const ListWorkers: React.FC = () => {
  const dispatch = useDispatch();
  const { items: workers, status } = useSelector((state: any) => state.workers);
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState<WorkerFormProps>({ worker_id: undefined, worker_name: '', worker_secret: '' });
  const [deleteConfirmId, setDeleteConfirmId] = useState<number | null>(null);

  useEffect(() => {
    if (status === null) {
      dispatch(fetchWorkers());
    }
  }, [dispatch, status]);

  // Open the modal for creating or editing
  const handleShowModal = (worker?: WorkerFormProps) => {
    if (worker) {
      setFormValues({ ...worker, worker_secret: '' }); // For editing, initialize worker_secret as empty
    } else {
      setFormValues({ worker_id: undefined, worker_name: '', worker_secret: '' }); // For new worker
    }
    setShowModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setFormValues({ worker_id: undefined, worker_name: '', worker_secret: '' });
  };

  // Handle form submission for create or update
  const handleSaveWorker = () => {
    const { worker_id, worker_name, worker_secret } = formValues;

    if (worker_id) {
      // Update worker, only include worker_secret if a new value is set
      const payload = {
        worker_id,
        worker_name,
        ...(worker_secret ? { worker_secret } : {}),
      };
      dispatch(updateWorker(payload));
    } else {
      // Create new worker
      dispatch(createWorker({ worker_name, worker_secret }));
    }
    handleCloseModal();
  };

  // Confirm delete modal
  const handleDeleteConfirm = (worker_id: number) => {
    setDeleteConfirmId(worker_id);
  };

  const confirmDeleteWorker = () => {
    if (deleteConfirmId) {
      dispatch(deleteWorker(deleteConfirmId));
    }
    setDeleteConfirmId(null);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Workers</h1>
        <Button variant="phoenix-primary" onClick={() => handleShowModal()}>
          New
        </Button>
      </div>
      {status === 'loading' && <h1>Loading...</h1>}
      {status === 'failed' && <h1>Error loading workers data.</h1>}
      {status === 'succeeded' && (
        <Table bordered hover size='sm'>
          <thead>
            <tr>
              <th>#</th>
              <th>Worker Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {workers.map((worker) => (
              <tr key={worker.worker_id}>
                <td>{worker.worker_id}</td>
                <td>{worker.worker_name}</td>
                <td>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="phoenix-secondary" onClick={() => handleShowModal(worker)}>Edit</Button>
                    <Button variant="phoenix-secondary" onClick={() => handleDeleteConfirm(worker.worker_id)}>Delete</Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Worker Form Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{formValues.worker_id ? 'Edit Worker' : 'New Worker'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formWorkerName">
              <Form.Label>Worker Name</Form.Label>
              <Form.Control
                type="text"
                value={formValues.worker_name || ''}
                onChange={(e) => {
                  const value = e.target.value.replace(/\s/g, ''); // Remove any spaces
                  setFormValues({ ...formValues, worker_name: value });
                }}
                placeholder="Enter worker name"
              />
            </Form.Group>
            <Form.Group controlId="formWorkerSecret">
              <Form.Label>Worker Secret</Form.Label>
              <Form.Control
                type="password"
                value={formValues.worker_secret || ''}
                onChange={(e) => setFormValues({ ...formValues, worker_secret: e.target.value })}
                placeholder="Enter new worker secret"
              />
              <Form.Text className="text-muted">Leave blank to keep the existing secret.</Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
          <Button variant="phoenix-primary" onClick={handleSaveWorker}>{formValues.worker_id ? 'Save Changes' : 'Create Worker'}</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={deleteConfirmId !== null} onHide={() => setDeleteConfirmId(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this worker?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteConfirmId(null)}>Cancel</Button>
          <Button variant="danger" onClick={confirmDeleteWorker}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListWorkers;
