import React from "react";
import { EdgeProps } from 'reactflow';

const LoopbackEdge: React.FC<EdgeProps> = ({ id, sourceX, sourceY, targetX, targetY, style }) => {
  // Determine the vertical offset for point2 and point3
  let intermediateY;
  if (sourceY < targetY) {
    // Source is higher than target, so set points 40px below the lowest point
    intermediateY = Math.max(sourceY, targetY) + 40;
  } else {
    // Source is lower than target, so set points 40px above the highest point
    intermediateY = Math.min(sourceY, targetY) - 40;
  }

  // Calculate each intermediate point for the loopback effect
  const point1 = `${sourceX + 20},${sourceY}`;
  const point2 = `${sourceX + 20},${intermediateY}`;
  const point3 = `${targetX - 20},${intermediateY}`;
  const point4 = `${targetX - 20},${targetY}`;

  // Combine all points into a polyline path
  const points = `${sourceX},${sourceY} ${point1} ${point2} ${point3} ${point4} ${targetX},${targetY}`;

  return (
    <polyline
      id={id}
      points={points}
      stroke={style?.stroke || 'black'}
      strokeWidth={1}
      strokeDasharray="5, 5" // Creates a dashed line pattern with dashes and gaps of 5px each
      fill="none"
      markerEnd="url(#arrowhead)"
    />
  );
};

export default LoopbackEdge;
