import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

interface Table {
    table_id: number;
    table_name: string;
    table_columns: object[]; // Columns structure as JSON
    table_privileges: object; // Privileges structure as JSON
}

interface TableState {
    items: Table[];
    status: string | null;
    currentTable: Table | null; // Store a single table item
    currentTableStatus: string | null; // Status for fetching a single table
}

const initialState: TableState = {
    items: [],
    status: null,
    currentTable: null, // Initial state for the single table
    currentTableStatus: null, // Initial status for fetching single table
};

// Fetch all tables
export const fetchTables = createAsyncThunk('table/fetchTables', async () => {
    const response = await api.get('/tables');
    return response.data;
});

// Fetch details of a single table
export const fetchTable = createAsyncThunk('table/fetchTable', async (table_id: number) => {
    const response = await api.get(`/tables/${table_id}`);
    return response.data;
});

// Create a new table
export const createTable = createAsyncThunk('table/createTable', async (table: Omit<Table, 'table_id'>) => {
    const response = await api.post('/tables', table);
    return response.data;
});

// Update an existing table
export const updateTable = createAsyncThunk('table/updateTable', async (table: Table) => {
    const response = await api.put(`/tables/${table.table_id}`, table);
    return response.data;
});

// Delete a table
export const deleteTable = createAsyncThunk('table/deleteTable', async (table_id: number) => {
    await api.delete(`/tables/${table_id}`);
    return table_id;
});

// Table Slice
const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch all tables
            .addCase(fetchTables.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTables.fulfilled, (state, action) => {
                state.items = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchTables.rejected, (state) => {
                state.status = 'failed';
            })
            // Fetch a single table
            .addCase(fetchTable.pending, (state) => {
                state.currentTableStatus = 'loading';
            })
            .addCase(fetchTable.fulfilled, (state, action) => {
                state.currentTable = action.payload;
                state.currentTableStatus = 'succeeded';
            })
            .addCase(fetchTable.rejected, (state) => {
                state.currentTableStatus = 'failed';
            })
            // Create new table
            .addCase(createTable.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            // Update table
            .addCase(updateTable.fulfilled, (state, action) => {
                const index = state.items.findIndex(
                    (table) => table.table_id === action.payload.table_id
                );
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            })
            // Delete table
            .addCase(deleteTable.fulfilled, (state, action) => {
                state.items = state.items.filter((table) => table.table_id !== action.payload);
            });
    },
});

export default tableSlice.reducer;
