import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";
import { fetchUserData } from "../../../redux/slices/authThunk";
// import CreateDevice from "./createDevice";
// import EditDevice from "./editDevice";


const LoggedInUser = () => {
    const username = useSelector((state: RootStateOrAny) => state.auth.userData.username);

    return (
        <div className="fw-bolder">
          {username}
        </div>
    );
};

export default LoggedInUser;
