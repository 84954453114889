// store.ts (Rename your store.js to store.ts)
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './rootReducer'; // Ensure rootReducer is typed
import rootSaga from './sagas'; 
import { composeWithDevTools } from '@redux-devtools/extension';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Configure the store
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({ thunk: true })
            .concat(sagaMiddleware)
            .concat(logger),
    devTools: composeWithDevTools,
});

// Run the root saga
sagaMiddleware.run(rootSaga);

// Derive the RootState type from the rootReducer
export type RootState = ReturnType<typeof rootReducer>;

export default store;
