import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

export interface Worker {
    worker_id: number;
    worker_name: string;
    priority?: number;
}

// types.ts or similar
export interface Connection {
    connection_id: number;
    connection_name: string;
    connection_type: string; // Add this field
    connection_parameters: {
      [key: string]: any; // Dynamic object for parameters like host, port, token, etc.
    };
    workers: Worker[];
  }
  


interface ConnectionState {
    items: Connection[];
    status: string | null;
}

const initialState: ConnectionState = {
    items: [],
    status: null,
};

// Fetch all connections
export const fetchConnections = createAsyncThunk('connections/fetchConnections', async () => {
    const response = await api.get('/connections');
    return response.data;
});

// Create a new connection
export const createConnection = createAsyncThunk('connections/createConnection', async (connection: Omit<Connection, 'connection_id'>) => {
    const response = await api.post('/connections', connection);
    return response.data;
});

// Update an existing connection
export const updateConnection = createAsyncThunk('connections/updateConnection', async (connection: Connection) => {
    const response = await api.put(`/connections/${connection.connection_id}`, connection);
    return response.data;
});

// Delete a connection by ID
export const deleteConnection = createAsyncThunk('connections/deleteConnection', async (connection_id: number) => {
    await api.delete(`/connections/${connection_id}`);
    return connection_id;
});

const connectionsSlice = createSlice({
    name: 'connections',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchConnections.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchConnections.fulfilled, (state, action) => {
                state.items = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchConnections.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(createConnection.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            .addCase(updateConnection.fulfilled, (state, action) => {
                const index = state.items.findIndex(
                    (connection) => connection.connection_id === action.payload.connection_id
                );
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            })
            .addCase(deleteConnection.fulfilled, (state, action) => {
                state.items = state.items.filter((connection) => connection.connection_id !== action.payload);
            });
    },
});

export default connectionsSlice.reducer;
