import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Modal } from 'react-bootstrap';
import api from 'services/api';
import { JSONPath } from 'jsonpath-plus';
import { v4 as uuidv4 } from 'uuid';
import Editor from 'react-simple-code-editor'; // Import the code editor component
import Prism from 'prismjs'; // Prism.js for syntax highlighting
import 'prismjs/components/prism-bash'; // Load Bash language
import 'prismjs/themes/prism-tomorrow.css'; // Import a Prism.js theme for styling (Optional)

const DynamicForm = ({ node, onSave, onChange }) => {
  const { t } = useTranslation();
  const { type: nodeType, data: nodeData } = node;
  const [config, setConfig] = useState<any>(null);
  const [formData, setFormData] = useState(nodeData || {});
  const [showModal, setShowModal] = useState(false);
  const [modalField, setModalField] = useState(''); // Track which field is being edited in modal
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    setFormData(nodeData || {});
  }, [nodeData]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await api.get(`/node_type_configs/${nodeType}`);
        setConfig(response.data.config);

        // Process config to mark options with handles
        const processedData = markOptionsWithHandles(response.data.config, nodeData);
        setFormData(processedData);
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    };

    fetchConfig();
  }, [nodeType]);

  // Utility function to mark options across fields that require handles
  const markOptionsWithHandles = (config, nodeData) => {
    const updatedNodeData = { ...nodeData };

    config.fields.forEach((field) => {
      if (field.generatesHandles && updatedNodeData[field.name]) {
        updatedNodeData[field.name] = updatedNodeData[field.name].map((option) => ({
          ...option,
          hasHandle: true, // Add a handle marker to each option in this field
          id: option.id || uuidv4(), // Ensure each option has a unique id
        }));
      }
    });

    return updatedNodeData;
  };

  const handleLocalInputChange = (field: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    onChange(field, value);
  };

  const handleLabelChange = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      label: value,
    }));
    onChange('label', value);
  };

  const handleSave = () => {
    onSave(formData);
  };

  const handleShowModal = (field: string, content: string) => {
    setModalField(field);
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleModalChange = (value: string) => {
    setModalContent(value);
    handleLocalInputChange(modalField, value);
  };

  const handleInputChange = (field: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    onChange(field, value);
  };

  // Handle options for OptionsKeys type
  const addOptionKey = (field: string) => {
    const newOption = {
      id: uuidv4(),  // Generate a UUID for each option
      key: `Option ${formData[field]?.length + 1 || 1}`,
      hasHandle: true, // Ensure new options also have handles if needed
    };
    const updatedOptions = [...(formData[field] || []), newOption];
    handleInputChange(field, updatedOptions);
  };

  const handleOptionChange = (field: string, index: number, value: string) => {
    const updatedOptions = formData[field].map((option, i) =>
      i === index ? { ...option, key: value } : option
    );
    handleInputChange(field, updatedOptions);
  };

  const removeOptionKey = (field: string, index: number) => {
    const updatedOptions = formData[field].filter((_: any, i: number) => i !== index);
    handleInputChange(field, updatedOptions);
  };

  // Handle key-value pairs for key_value_pair type
  const addKeyValuePair = (field: string) => {
    const newKeyValue = { key: '', value: '' };
    const updatedPairs = [...(formData[field] || []), newKeyValue];
    handleInputChange(field, updatedPairs);
  };

  const handleKeyValuePairChange = (field: string, index: number, keyOrValue: 'key' | 'value', value: string) => {
    const updatedPairs = formData[field].map((pair: any, i: number) =>
      i === index ? { ...pair, [keyOrValue]: value } : pair
    );
    handleInputChange(field, updatedPairs);
  };

  const removeKeyValuePair = (field: string, index: number) => {
    const updatedPairs = formData[field].filter((_: any, i: number) => i !== index);
    handleInputChange(field, updatedPairs);
  };

  // Handle array fields for Array type
  const addArrayItem = (field: string) => {
    const updatedArray = [...(formData[field] || []), ''];
    handleInputChange(field, updatedArray);
  };

  const handleArrayItemChange = (field: string, index: number, value: string) => {
    const updatedArray = formData[field].map((item: any, i: number) =>
      i === index ? value : item
    );
    handleInputChange(field, updatedArray);
  };

  const removeArrayItem = (field: string, index: number) => {
    const updatedArray = formData[field].filter((_: any, i: number) => i !== index);
    handleInputChange(field, updatedArray);
  };

  const renderArrayForm = (field: any) => {
    const arrayData = Array.isArray(formData[field.name]) ? formData[field.name] : [];

    return (
      <>
        <Form.Label className="d-block">{field.name}</Form.Label>
        {arrayData.map((item: string, index: number) => (
          <div key={`${field.name}-${index}`} className="d-flex align-items-center mt-2">
            <Form.Group controlId={`array-item-${index}`} className="me-2" style={{ flex: 1 }}>
              <Form.Label className="d-block">{`Item ${index + 1}`}</Form.Label>
              <Form.Control
                type="text"
                value={item}
                onChange={(e) => handleArrayItemChange(field.name, index, e.target.value)}
              />
            </Form.Group>
            <Button variant="danger" size="sm" onClick={() => removeArrayItem(field.name, index)}>&minus;</Button>
          </div>
        ))}
        <Button variant="secondary" className="mt-3 d-block" onClick={() => addArrayItem(field.name)}>
          + {field.key_name || 'Add Item'}
        </Button>
      </>
    );
  };

  const renderKeyValueForm = (field: any) => (
    <>
      <Form.Label className="d-block">{field.name}</Form.Label>
      {(formData[field.name] || []).map((pair: any, index: number) => (
        <div key={`${field.name}-${index}`} className="d-flex align-items-center mt-2">
          <Form.Group controlId={`key-${index}`} className="me-2" style={{ flex: 1 }}>
            <Form.Label className="d-block">{`Key ${index + 1}`}</Form.Label>
            <Form.Control
              type="text"
              value={pair.key}
              onChange={(e) => handleKeyValuePairChange(field.name, index, 'key', e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId={`value-${index}`} className="me-2" style={{ flex: 1 }}>
            <Form.Label className="d-block">{`Value ${index + 1}`}</Form.Label>
            <Form.Control
              type="text"
              value={pair.value}
              onChange={(e) => handleKeyValuePairChange(field.name, index, 'value', e.target.value)}
            />
          </Form.Group>
          <Button variant="danger" size="sm" onClick={() => removeKeyValuePair(field.name, index)}>&minus;</Button>
        </div>
      ))}
      <Button variant="secondary" className="mt-3 d-block" onClick={() => addKeyValuePair(field.name)}>
        + {field.key_name || 'Add Key-Value Pair'}
      </Button>
    </>
  );

  const renderOptionsForm = (field: any) => (
    <>
      <Form.Label className="d-block">{field.name}</Form.Label>
      {formData[field.name]?.map((option: any, index: number) => (
        <div key={option.id} className="d-flex align-items-center mt-2">
          <Form.Group controlId={`optionKey${index}`} className="me-2" style={{ flex: 1 }}>
            <Form.Label className="d-block">{`Option Key ${index + 1}`}</Form.Label>
            <Form.Control
              type="text"
              value={option.key}
              onChange={(e) => handleOptionChange(field.name, index, e.target.value)}
            />
          </Form.Group>
          <Button variant="danger" size="sm" onClick={() => removeOptionKey(field.name, index)}>&minus;</Button>
        </div>
      ))}
      <Button variant="secondary" className="mt-3 d-block" onClick={() => addOptionKey(field.name)}>
        Add option
      </Button>
    </>
  );

  const renderField = (field: any) => {
    switch (field.type) {
      case 'Dropdown':
        return (
          <Form.Group controlId={`form${field.name}`} key={field.name}>
            <Form.Label className="d-block">{field.name}</Form.Label>
            <Form.Control
              as="select"
              value={formData[field.name] || ''}
              onChange={(e) => handleLocalInputChange(field.name, e.target.value)}
            >
              <option value="">Select an option</option>
              <DropdownOptions
                url={field.ValuesApi}
                jsonPath={field.JSONPath}
                selectedValue={formData[field.name]}
              />
            </Form.Control>
          </Form.Group>
        );
      case 'longText':
        return (
          <Form.Group controlId={`form${field.name}`} key={field.name}>
            <Form.Label className="d-block">{field.name}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData[field.name] || ''}
              onChange={(e) => handleLocalInputChange(field.name, e.target.value)}
              onClick={() => handleShowModal(field.name, formData[field.name] || '')}
            />
          </Form.Group>
        );
      case 'Boolean':
        return (
          <Form.Group controlId={`form${field.name}`} key={field.name}>
            <Form.Check
              type="checkbox"
              label={field.name}
              checked={formData[field.name] !== undefined ? formData[field.name] : field.Default || false}
              onChange={(e) => handleLocalInputChange(field.name, e.target.checked)}
            />
          </Form.Group>
        );
      case 'OptionsKeys':
        return renderOptionsForm(field); // Pass the field object to the renderOptionsForm
      case 'key_value_pair':
        return renderKeyValueForm(field);
      case 'Array':
        return renderArrayForm(field);
      default:
        return (
          <Form.Group controlId={`form${field.name}`} key={field.name}>
            <Form.Label className="d-block">{field.name}</Form.Label>
            <Form.Control
              type="text"
              value={formData[field.name] || ''}
              onChange={(e) => handleLocalInputChange(field.name, e.target.value)}
            />
          </Form.Group>
        );
    }
  };

  if (!config) return null;

  return (
    <>
      <Form>
        <Form.Group controlId="formNodeLabel">
          <Form.Label className="d-block">Node Label</Form.Label>
          <Form.Control
            type="text"
            value={formData.label || ''}
            onChange={(e) => handleLabelChange(e.target.value)}
          />
        </Form.Group>
        
        {config.fields.map((field: any) => renderField(field))}
      </Form>

      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{t('content')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Editor
            value={modalContent}
            onValueChange={handleModalChange}
            highlight={(code) => Prism.highlight(code, Prism.languages.bash, 'bash')}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              backgroundColor: '#2d2d2d',
              color: '#f8f8f2',
              minHeight: '300px',
              borderRadius: '5px'
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DropdownOptions = ({ url, jsonPath, selectedValue }: any) => {
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await api.get(url);
        const jsonData = response.data;

        const filteredOptions = JSONPath({ path: jsonPath, json: jsonData });
        setOptions(filteredOptions);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, [url, jsonPath]);

  return (
    <>
      {options.map((option) => (
        <option key={option} value={option} selected={option === selectedValue}>
          {option}
        </option>
      ))}
    </>
  );
};

export default DynamicForm;
