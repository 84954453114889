import { createSlice } from '@reduxjs/toolkit';
import { fetchRoles, fetchRole, createRole, updateRole, deleteRole } from './rolesThunk';

const initialState = {
    roles: [],
    loading: false,
    createRoleLoading: false,
    updateRoleLoading: false,
    deleteRoleLoading: false,
};

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

        // ###### FETCH ALL ROLES
        .addCase(fetchRoles.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchRoles.fulfilled, (state, action) => {
            state.roles = action.payload; // Roles include ssoroleid
            state.loading = false;
        })
        .addCase(fetchRoles.rejected, (state) => {
            state.loading = false;
            state.roles = [];
        })

        // ###### FETCH A SPECIFIC ROLE (fetchRole)
        .addCase(fetchRole.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchRole.fulfilled, (state, action) => {
            const role = action.payload;
            const index = state.roles.findIndex((r) => r.role_id === role.role_id);

            if (index !== -1) {
                state.roles[index] = role; // Update the role with ssoroleid and privileges
            } else {
                state.roles.push(role); // Add new role with ssoroleid and privileges
            }

            state.loading = false;
        })
        .addCase(fetchRole.rejected, (state) => {
            state.loading = false;
        })

        // ###### CREATE ROLE
        .addCase(createRole.pending, (state) => {
            state.createRoleLoading = true;
        })
        .addCase(createRole.fulfilled, (state, action) => {
            state.roles.push(action.payload); // Add new role with ssoroleid and privileges to the state
            state.createRoleLoading = false;
        })
        .addCase(createRole.rejected, (state) => {
            state.createRoleLoading = false;
        })

        // ###### UPDATE ROLE
        .addCase(updateRole.pending, (state) => {
            state.updateRoleLoading = true;
        })
        .addCase(updateRole.fulfilled, (state, action) => {
            const updatedRole = action.payload;
            const index = state.roles.findIndex((r) => r.role_id === updatedRole.role_id);
            if (index !== -1) {
                state.roles[index] = updatedRole; // Update the role with ssoroleid and privileges
            }
            state.updateRoleLoading = false;
        })
        .addCase(updateRole.rejected, (state) => {
            state.updateRoleLoading = false;
        })

        // ###### DELETE ROLE
        .addCase(deleteRole.pending, (state) => {
            state.deleteRoleLoading = true;
        })
        .addCase(deleteRole.fulfilled, (state, action) => {
            state.roles = state.roles.filter(role => role.role_id !== action.payload.role_id);
            state.deleteRoleLoading = false;
        })
        .addCase(deleteRole.rejected, (state) => {
            state.deleteRoleLoading = false;
        });
    },
})

export default rolesSlice.reducer;
