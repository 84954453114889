import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateOrAny } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import logo from 'assets/img/icons/LogoConnectivityPlatform.png';
// import { fetchFlowInstances } from '../../../redux/slices/flowsSlice';

// import ExecutionStatusChart from './ExecutionStatusChart';
// import FlowExecutionTimelineChart from './FlowExecutionTimelineChart';


const NcpDescription = () => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();

  // const flowInstances = useSelector(
  //   (state: RootStateOrAny) => state.flows.flowInstances
  // );

  // Dispatch fetchFlowInstances when the component mounts
  // useEffect(() => {
  //   dispatch(fetchFlowInstances({ limit: 2000 }));
  // }, [dispatch]);

  return (
    <>
      <div className="text-center">
       <h1>Dashboard</h1>
      </div>
      {/* <div>
        <Row xs={1} md={2} className="g-4">
        <Col>
            <Card>
              <Card.Body>
                <Card.Title>{t('execution-status-breakdown')}</Card.Title>

                <ExecutionStatusChart flowInstances={flowInstances} />
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Body>
                <Card.Title>{t('Flow-execution-count-over-time')}</Card.Title>
                <FlowExecutionTimelineChart flowInstances={flowInstances} />
              </Card.Body>
            </Card>
          </Col>

          {/* Repeat for other charts
        </Row>
      </div> */}
    </>
  );
};

export default NcpDescription;
