// backendUrl.js

export const getOauthConfig = async () => {
    try {
      const response = await fetch('/config.json');
      if (!response.ok) {
        throw new Error('Failed to load config.json');
      }
      const config = await response.json();
      return config.OAUTH_CONFIG;
    } catch (error) {
      console.error('Error loading backend URL from config.json:', error);
    }
  };
  