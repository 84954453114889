import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Table, Spinner, Container, Form, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkflowDetails, fetchWorkflows, respondToWorkflowAction, saveWorkflowAction } from '../../../../redux/slices/workflowsSlice';
import { RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Split from 'react-split';

const WorkflowDetailedView = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { workflowId } = useParams<{ workflowId: string }>();
    const { workflows, loading } = useSelector((state: RootState) => state.workflows);

    // Determine selected workflow based on URL param or highest workflow_id
    const resolvedWorkflowId = workflowId ? Number(workflowId) : Math.max(...workflows.map(wf => wf.workflow_id), 0);
    const selectedWorkflow = workflows.find((workflow) => workflow.workflow_id === resolvedWorkflowId);

    const [inputValues, setInputValues] = useState<{ [key: number]: { [label: string]: string } }>({});
    const [showSaveButton, setShowSaveButton] = useState<{ [key: number]: boolean }>({});
    const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth >= 768);

    // Fetch workflows on mount and refetch details if resolvedWorkflowId changes
    useEffect(() => {
        dispatch(fetchWorkflows());
    }, [dispatch]);

    useEffect(() => {
        if (resolvedWorkflowId) {
            dispatch(fetchWorkflowDetails(resolvedWorkflowId));
            setInputValues({}); // Clear form values on workflow change
            setShowSaveButton({});
        }
    }, [dispatch, resolvedWorkflowId]);

    // Populate input values when workflow details are loaded
    useEffect(() => {
        if (selectedWorkflow) {
            const initialInputValues: { [key: number]: { [label: string]: string } } = {};
            selectedWorkflow.workflows_actions?.forEach((action) => {
                initialInputValues[action.workflow_action_id] = Array.isArray(action.response_inputs)
                    ? action.response_inputs.reduce((acc, input) => {
                        acc[input.name] = input.value;
                        return acc;
                    }, {} as { [label: string]: string })
                    : {};
            });
            setInputValues(initialInputValues);
        }
    }, [selectedWorkflow]);

    const handleInputChange = (workflow_action_id: number, label: string, value: string) => {
        setInputValues((prev) => ({
            ...prev,
            [workflow_action_id]: {
                ...(prev[workflow_action_id] || {}),
                [label]: value
            }
        }));

        const updatedValues = {
            ...(inputValues[workflow_action_id] || {}),
            [label]: value
        };

        setShowSaveButton((prev) => ({
            ...prev,
            [workflow_action_id]: Object.values(updatedValues).some(
                (inputValue) => typeof inputValue === "string" && inputValue.trim() !== ""
            )
        }));
    };

    const handleSave = (workflow_action_id: number) => {
        const responseInputs = Object.entries(inputValues[workflow_action_id] || {}).map(([name, value]) => ({ name, value }));
        dispatch(saveWorkflowAction({ workflow_action_id, response_inputs: responseInputs }));
        setShowSaveButton((prev) => ({ ...prev, [workflow_action_id]: false }));
    };

    // Sidebar visibility based on screen resize
    const handleResize = () => {
        setIsSidebarVisible(window.innerWidth >= 768);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (loading) return <Spinner animation="border" />;

    return (
        <Container fluid className="p-0">
            <Split className="d-flex" sizes={[20, 80]} minSize={150} expandToMin>
                {/* Sidebar showing workflow list */}
                {isSidebarVisible && workflows && (
                    <div
                    style={{
                        overflowY: 'scroll',
                        height: 'calc(100vh - 160px)',
                        borderRight: '1px solid #dee2e6',
                        padding: '0',
                        boxShadow: 'inset 0px -8px 8px -8px rgba(0, 0, 0, 0.3)' // Subtle shadow effect
                    }}
                    onScroll={(e) => {
                        // Adjust shadow based on scroll position if needed
                        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
                        e.currentTarget.style.boxShadow =
                            scrollTop < scrollHeight - clientHeight
                                ? 'inset 0px -8px 8px -8px rgba(0, 0, 0, 0.3)'
                                : 'none';
                    }}
                >
                    <h3>INBOX</h3>
                    <ListGroup variant="flush">
                        {workflows.map((workflow) => (
                            <ListGroup.Item
                                key={workflow.workflow_id}
                                as={Link}
                                to={`/inbox/${workflow.workflow_id}`}
                                active={workflow.workflow_id === resolvedWorkflowId}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <div>{workflow.workflow_subject}</div>
                                <small className="text-muted">
                                    {new Date(workflow.created_at).toLocaleString('nl-NL', {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false
                                    })}
                                </small>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
                
                
                )}

                {/* Main content for selected workflow details */}
                {selectedWorkflow ? (
                    <div style={{ padding: '0.5rem', overflow: 'auto', height: 'calc(100vh - 160px)', flexGrow: 1 }}>
                        {/* Header displaying selected workflow details */}
                        <div className="mb-4">
                            <h3>WORKFLOW {selectedWorkflow.workflow_id}</h3>
                            <p><strong>{t('subject')}: </strong>{selectedWorkflow.workflow_subject}</p>
                            <p><strong>{t('category')}: </strong>{selectedWorkflow.workflow_category}</p>
                            <p><strong>{t('created_at')}: </strong>
                            {new Date(selectedWorkflow.created_at).toLocaleString('nl-NL', {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            })}



                                
                            </p>
                            <p><strong>{t('workflows.last_updated')}: </strong>
                                {new Date(selectedWorkflow.updated_at).toLocaleString('nl-NL', {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                })}
                                </p>
                        </div>

                        {/* Table displaying actions */}
                        <Table hover size="sm">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>{t('date')}</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedWorkflow.workflows_actions.map((action) => (
                                    <tr key={action.workflow_action_id}>
                                        <td>{action.workflow_action_id}</td>
                                        <td>
                                            {new Date(action.created_at).toLocaleString('nl-NL', {
                                                day: 'numeric',
                                                month: 'long',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: false
                                            })}
                                        </td>
                                        <td>
                                            <div>
                                                {action.response ? (
                                                    <>
                                                        <p>
                                                            <strong>{t('workflows.chosen_option')}: </strong>
                                                            {action.response.key} {' '}
                                                            ({action.response.user}{' '}
                                                            {new Date(action.response.timestamp).toLocaleString('nl-NL', {
                                                                day: 'numeric',
                                                                month: 'long',
                                                                year: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: false
                                                            })})
                                                        </p>
                                                        {Array.isArray(action.response_inputs) && action.response_inputs.length > 0 && (
                                                            <div>
                                                                <p><strong>{t('workflows.response_inputs')}: </strong></p>
                                                                {action.response_inputs.map((input) => (
                                                                    <p key={input.name}>
                                                                        <strong>{input.name}: </strong> {input.value || t('empty')}
                                                                    </p>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <div>
    <div className="d-flex justify-content-between">
        <strong>{t('workflows.chosen_option')}: </strong>
        <div className="ms-auto">
            <ButtonGroup>
                {action.response_options?.map((option) => (
                    <Button
                        key={option.key}
                        variant="outline-primary"
                        onClick={() => {
                            handleSave(action.workflow_action_id);
                            dispatch(respondToWorkflowAction({
                                workflow_action_id: action.workflow_action_id,
                                chosen_key: option.key
                            }));
                        }}
                        
                    >
                        {option.key}
                    </Button>
                ))}
            </ButtonGroup>
        </div>
    </div>

    <p><strong>{t('subject')}: </strong>{action.subject || t('empty')}</p>
    <p><strong>{t('message')}: </strong>{action.message || t('empty')}</p>

    {action.response_input_options && action.response_input_options.length > 0 && (
        <div>
            <p><strong>{t('response_inputs')}: </strong></p>
            {action.response_input_options.map((label) => (
                <Form.Group key={label} className="mb-2">
                    <Form.Label>{label}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        id={`${action.workflow_action_id}-${label}`}
                        value={inputValues[action.workflow_action_id]?.[label] || ""}
                        onChange={(e) =>
                            handleInputChange(action.workflow_action_id, label, e.target.value)
                        }
                    />
                </Form.Group>
            ))}
            {showSaveButton[action.workflow_action_id] && (
                <Button
                    variant="primary"
                    onClick={() => handleSave(action.workflow_action_id)}
                >
                    {t('save')}
                </Button>
            )}
        </div>
    )}
</div>

                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <p>{t('workflows.no_selected_workflow')}</p>
                )}
            </Split>
        </Container>
    );
};

export default WorkflowDetailedView;
