import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFlows, deleteFlow, runFlow } from '../../../../redux/slices/flowsSlice';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownFlows from '../../../../components/common/ActionDropdownFlows';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';
import FlowSubmitScreen from './flowSubmitScreen'; // Import the new modal component

// Define or import the Flow interface
interface Flow {
  flow_id: number;
  flow_name: string;
  flow_definition: string;
}

const ListFlows = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const flows = useSelector((state: any) => state.flows.flows);
  const loading = useSelector((state: any) => state.flows.loading);
  const error = useSelector((state: any) => state.flows.error); // Get error from state
  const errorCode = useSelector((state: any) => state.flows.errorCode); // Get error from state

  // State for modal
  const [showModal, setShowModal] = useState(false);
  const [currentFlowId, setCurrentFlowId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(fetchFlows());
  }, [dispatch]);

  const handleAction = async (flow_id: number, action: string) => {
    if (action === 'remove') {
      dispatch(deleteFlow(flow_id));
    } else if (action === 'edit') {
      const url = "/apps/scheduler/editFlow/" + flow_id;
      window.location.href = url;
    } else if (action === 'run') {
      dispatch(runFlow({ flow_id }));
    } else if (action === 'schedule') {
      setCurrentFlowId(flow_id); // Set the current flow ID for scheduling
      setShowModal(true); // Show the modal when scheduling is selected
    }
  };

  const handleScheduleSubmit = (scheduleDetails: any) => {
    if (currentFlowId !== null) {
      dispatch(runFlow({ flow_id: currentFlowId, scheduleDetails }));
    }
    setShowModal(false); // Close the modal after submitting
  };

  const columns: ColumnDef<Flow>[] = [
    {
      accessorKey: 'flow_name',
      header: t('flow-name'),
      cell: ({ row }) => (
        <span
          onClick={() => handleAction(row.original.flow_id, 'view')}
          style={{ cursor: 'pointer', color: '#007bff' }}
        >
          {row.original.flow_name}
        </span>
      ),
    },
    {
      id: 'action',
      cell: ({ row }) => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownFlows flow_id={row.original.flow_id} onAction={handleAction} />
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' },
      },
    },
  ];

  const table = useAdvanceTable({
    data: flows,
    columns,
    pageSize: 20,
    pagination: true,
    selection: true,
    sortable: true,
  });

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleNewFlowClick = () => {
    navigate('/apps/scheduler/floweditor');
  };

  return (
    <Container fluid className="p-0">

      {error && (
        <Row className="m-0 mb-4">
          <Col className="p-0">
            <Alert variant="outline-danger">
              {errorCode === 403 ? t('error-not-authorized') : t('error-generic')}
            </Alert>
          </Col>
        </Row>
      )}
      <Row className="m-0 mb-4">
        <Col className="p-0 d-flex justify-content-between align-items-center">
          <SearchBox
            placeholder={t('search') + '...'}
            size="sm"
            onChange={handleSearchInputChange}
            className="mx-auto"
          />
          <Button variant="primary" size="sm" onClick={handleNewFlowClick}>
            {t('button.newe')} Flow
          </Button>
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="p-0">
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              tableProps={{
                size: 'sm',
                className: 'phoenix-table fs-9 mb-0 border-top border-translucent w-100',
              }}
              rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            />
            <AdvanceTableFooter navBtn />
          </AdvanceTableProvider>
        </Col>
      </Row>

      {/* Flow Submit Screen for scheduling */}
      <FlowSubmitScreen
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={handleScheduleSubmit}
      />
    </Container>
  );
};

export default ListFlows;
