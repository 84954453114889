import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

// Define the shape of a Workflow and WorkflowAction
interface WorkflowAction {
    workflow_action_id: number;
    workflow_id: number;
    action_type: string;
    action_details: Record<string, any>;
    response?: { id: string; key: string; user?: string; timestamp?: string };
    response_options?: Array<{ id: string; key: string; handHandle?: boolean }>;
    response_inputs?: Array<{ name: string; value: string }>;
    response_input_options?: Array<string>;
    created_at: string;
    modified_at: string;
    subject: string;
    message: string;
}

interface Workflow {
    workflow_id: number;
    workflow_category: string;
    node_instance_id: number;
    node_id: string;
    workflow_subject: string;
    chosen_option?: { id: string; key: string };
    response_options?: Array<{ id: string; key: string }>;
    workflows_actions: WorkflowAction[];
    created_at: string;
    updated_at: string;
}

interface WorkflowState {
    workflows: Workflow[];
    loading: boolean;
    createLoading: boolean;
    updateLoading: boolean;
    deleteLoading: boolean;
    error: string | null;
}

// Thunks for asynchronous operations

// Fetch all workflows assigned to the current user
export const fetchWorkflows = createAsyncThunk<Workflow[]>('workflows/fetchWorkflows', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get('/workflows');
        return response.data as Workflow[];
    } catch (error) {
        return rejectWithValue(error.response?.data || 'Error fetching workflows');
    }
});

// Fetch workflow details and actions for a specific workflow by workflow_id
export const fetchWorkflowDetails = createAsyncThunk<Workflow, number>(
    'workflows/fetchWorkflowDetails',
    async (workflowId, { rejectWithValue }) => {
        try {
            const response = await api.get(`/workflows/${workflowId}`);
            return response.data as Workflow;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error fetching workflow details');
        }
    }
);

// Respond to a workflow action with a chosen option
export const respondToWorkflowAction = createAsyncThunk<
    WorkflowAction,
    { workflow_action_id: number; chosen_key: string }
>(
    'workflows/respondToWorkflowAction',
    async ({ workflow_action_id, chosen_key }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/workflows/respond/${workflow_action_id}`, { chosen_key });
            return response.data as WorkflowAction;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error responding to workflow action');
        }
    }
);

// Save workflow action with filled response inputs
export const saveWorkflowAction = createAsyncThunk<
    WorkflowAction,
    { workflow_action_id: number; response_inputs: Array<{ name: string; value: string }> }
>(
    'workflows/saveWorkflowAction',
    async ({ workflow_action_id, response_inputs }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/workflowActions/${workflow_action_id}`, { response_inputs });
            return response.data as WorkflowAction;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error saving workflow action');
        }
    }
);

// Initial state for workflows slice
const initialState: WorkflowState = {
    workflows: [],
    loading: false,
    createLoading: false,
    updateLoading: false,
    deleteLoading: false,
    error: null,
};

// Workflows slice
const workflowsSlice = createSlice({
    name: 'workflows',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch workflows
            .addCase(fetchWorkflows.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWorkflows.fulfilled, (state, action) => {
                state.loading = false;
                state.workflows = action.payload;
            })
            .addCase(fetchWorkflows.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            // Fetch workflow details for a specific workflow
            .addCase(fetchWorkflowDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWorkflowDetails.fulfilled, (state, action) => {
                state.loading = false;
                const workflowIndex = state.workflows.findIndex((workflow) => workflow.workflow_id === action.payload.workflow_id);

                if (workflowIndex !== -1) {
                    // Update the existing workflow in state
                    state.workflows[workflowIndex] = action.payload;
                } else {
                    // If workflow is not in the state, add it
                    state.workflows.push(action.payload);
                }
            })
            .addCase(fetchWorkflowDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            // Respond to workflow action
            .addCase(respondToWorkflowAction.pending, (state) => {
                state.updateLoading = true;
                state.error = null;
            })
            .addCase(respondToWorkflowAction.fulfilled, (state, action) => {
                state.updateLoading = false;
                const { workflow_id, workflow_action_id } = action.payload;
                const workflowIndex = state.workflows.findIndex((workflow) => workflow.workflow_id === workflow_id);
                
                if (workflowIndex !== -1) {
                    const workflow = state.workflows[workflowIndex];
                    const actionIndex = workflow.workflows_actions.findIndex(
                        (act) => act.workflow_action_id === workflow_action_id
                    );
                    if (actionIndex !== -1) {
                        // Update the workflow action with the chosen option
                        workflow.workflows_actions[actionIndex] = action.payload;
                    }
                }
            })
            .addCase(respondToWorkflowAction.rejected, (state, action) => {
                state.updateLoading = false;
                state.error = action.payload as string;
            })

            // Save workflow action with response inputs
            .addCase(saveWorkflowAction.pending, (state) => {
                state.updateLoading = true;
                state.error = null;
            })
            .addCase(saveWorkflowAction.fulfilled, (state, action) => {
                state.updateLoading = false;
                const { workflow_id, workflow_action_id } = action.payload;
                const workflowIndex = state.workflows.findIndex((workflow) => workflow.workflow_id === workflow_id);

                if (workflowIndex !== -1) {
                    const workflow = state.workflows[workflowIndex];
                    const actionIndex = workflow.workflows_actions.findIndex(
                        (act) => act.workflow_action_id === workflow_action_id
                    );
                    if (actionIndex !== -1) {
                        // Update the workflow action with the saved response inputs
                        workflow.workflows_actions[actionIndex] = action.payload;
                    }
                }
            })
            .addCase(saveWorkflowAction.rejected, (state, action) => {
                state.updateLoading = false;
                state.error = action.payload as string;
            });
    },
});

export default workflowsSlice.reducer;
