import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/base/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { fetchRole, updateRole, createRole } from '../../../../redux/slices/rolesThunk'; // Now import createRole
import Table from 'react-bootstrap/Table';
import _ from 'lodash'; // To do deep comparison between arrays

const EditRole = (props: { stateId?: number; newRole?: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  // Component states
  const [show, setShow] = useState(false);
  const [name, setName] = useState<string>(''); // Store the role's name
  const [ssoroleid, setSsoRoleId] = useState<string | null>(null); // Store the SSO Role ID if changed
  const [ssoroleidTouched, setSsoRoleIdTouched] = useState(false); // Track if ssoroleid has been modified
  const [privileges, setPrivileges] = useState<any[]>([]); // Store privileges as an array of objects with assigned flags
  const [initialPrivileges, setInitialPrivileges] = useState<any[]>([]); // Store initial privileges to compare changes
  const [filter, setFilter] = useState<string>(''); // Filter for privileges

  const role_id = useSelector((state: RootStateOrAny) => props.newRole ? undefined : state.roles.roles[props.stateId]?.role_id);

  // Show/Hide modal handlers
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    if (!props.newRole) {
      dispatch(fetchRole(role_id)); // Fetch the role and its privileges when opening the modal
    }
  };

  // Watch for the updated role when fetched by fetchRole
  const fetchedRole = useSelector((state: RootStateOrAny) => props.newRole ? null : state.roles.roles.find((r: any) => r.role_id === role_id));

  useEffect(() => {
    if (fetchedRole && !props.newRole) {
      setName(fetchedRole.name);
      setPrivileges(fetchedRole.privileges || []); // Set privileges with their assigned flag
      setInitialPrivileges(_.cloneDeep(fetchedRole.privileges || [])); // Store a deep copy of the initial privileges
    }
  }, [fetchedRole, props.newRole]);

  // Handle creating or updating the role
  const handleSubmitRole = async (e: React.FormEvent) => {
    e.preventDefault();
    const body: any = { name };

    // Only include privileges in the API call if they have changed
    if (!_.isEqual(privileges, initialPrivileges)) {
      body.privileges = privileges;
    }

    // Only include ssoroleid if it has been touched/modified
    if (ssoroleidTouched) {
      body.ssoroleid = ssoroleid;
    }

    // Check if it's a new role or updating an existing one
    if (props.newRole) {
      dispatch(createRole(body)); // Create new role
    } else {
      body.role_id = role_id;
      dispatch(updateRole(body)); // Update existing role
    }

    handleClose(); // Close modal after updating/creating
  };

  // Filter privileges by name
  const filteredPrivileges = privileges.filter(privilege => 
    privilege.name.toLowerCase().includes(filter.toLowerCase())
  );

  // Toggle privilege assignment
  const togglePrivilege = (privilege_id: string) => {
    setPrivileges(prev =>
      prev.map(priv =>
        priv.privilege_id === privilege_id
          ? { ...priv, assigned: !priv.assigned }
          : priv
      )
    );
  };

  // Handle SSO Role ID input change
  const handleSsoRoleIdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSsoRoleId(ev.target.value); // Update the SSO Role ID
    setSsoRoleIdTouched(true); // Mark that the SSO Role ID has been modified
  };

  return (
    <>
      <Button variant="phoenix-secondary" onClick={handleShow}>
        {props.newRole ? t('button.new') : t('button.edit')}
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
        <Modal.Header>
          <Modal.Title>{props.newRole ? t('button.newe') : t('button.edit')} {t('roles.role')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {!props.newRole && (
            <Form.Group as={Row} className="mb-3" controlId="formRoleID">
              <Form.Label column sm="2">Role ID</Form.Label>
              <Col sm="10">
                <Form.Control type="text" value={role_id} plaintext readOnly />
              </Col>
            </Form.Group>
          )} */}

          <Form.Group as={Row} className="mb-3" controlId="formRoleName">
            <Form.Label column sm="2">{t('name')}</Form.Label>
            <Col sm="10">
              <Form.Control 
                type="text" 
                value={name} 
                onChange={ev => setName(ev.target.value)} 
              />
            </Col>
          </Form.Group>

          {/* SSO Role ID Input */}
          <Form.Group as={Row} className="mb-3" controlId="formSSORoleID">
            <Form.Label column sm="2">SSO {t('roles.role')} ID</Form.Label>
            <Col sm="10">
              <Form.Control 
                type="text" 
                defaultValue={props.newRole ? '' : fetchedRole?.ssoroleid || ''} // Set initial value without modifying state
                onChange={handleSsoRoleIdChange} // Only update state when user interacts with the field
              />
            </Col>
          </Form.Group>

          {/* Filter Input for Privileges */}
          <Form.Group as={Row} className="mb-3" controlId="formPrivilegeFilter">
            <Form.Label column sm="2">Filter Privileges</Form.Label>
            <Col sm="10">
              <Form.Control 
                type="text" 
                placeholder={t('search')+'...'} 
                value={filter}
                onChange={(e) => setFilter(e.target.value)} 
              />
            </Col>
          </Form.Group>

          {/* Privileges Table */}
          <h5>Privileges</h5>
          {privileges.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Privilege {t('name')}</th>
                  <th>{t('roles.assigned')}</th>
                </tr>
              </thead>
              <tbody>
                {filteredPrivileges.map((privilege, index) => (
                  <tr key={index}>
                    <td>{privilege.name}</td>
                    <td>
                      <Form.Check 
                        type="checkbox"
                        checked={privilege.assigned}
                        onChange={() => togglePrivilege(privilege.privilege_id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>{t('roles.no-privileges-assigned-to-this-role')}.</p>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="phoenix-danger" onClick={handleClose} startIcon={<FontAwesomeIcon icon={faWindowClose} />}>
            {t('button.close')}
          </Button>
          <Button variant="phoenix-primary" onClick={handleSubmitRole} startIcon={<FontAwesomeIcon icon={faSave} />}>
            {props.newRole ? t('button.create') : t('button.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditRole;
